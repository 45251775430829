import React, { useState } from 'react'
import axios from 'axios'
import Dialog from './dialogs/Dialog'
import CheckBox from './textfield/CheckBox'

const Login = ({changeLoginStatus, editContent_, changeSettings_}) => {

    const [error, changeError] = useState('')

    const [username, changeUsername] = useState('')
    const [password, changePassword] = useState('')
    const [stay_logged_in, changeStayLoggedIn] = useState(false)

    const handleUsernameChange = e => changeUsername(e.target.value)
    const handlePasswordChange = e => changePassword(e.target.value)
    const handleStayLoggedInChange = () => changeStayLoggedIn(!stay_logged_in)

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {


        if (!username || !password) {
            changeError('Please enter a username and a password.')
        }

        axios.post('/api/authorization.php', {
            username: username,
            password: password,
            stay_logged_in: stay_logged_in,
        })
        .then(function (res) {
            if (res.data.successful) {
                changeLoginStatus(true);
                //const ppc = res.data.content.content.replace(/\n/g,'\\n');
			    const content = JSON.parse(decodeURIComponent(res.data.content.content))
				const settings = JSON.parse(res.data.content.settings)
				!!content && editContent_(content);
				!!settings && changeSettings_(settings);
            }
            else {

                switch (res.data.error) {
                    case 2:
                        changeError('Incorrect username or password.');
                        break;
                    default:
                        changeError('An error occured. Please try logging in again later.')
                        break;
                }

            }
        })
        .catch(function (error) {
            //console.log(error.response.status);
            changeError('An error occured. Please try logging in again later.')
        });
    }

    const title = <>Login</>

    const content = <>
        <form className='login_dialog_form'>
            {error && <div className="error_message">{error}</div>}
            <label htmlFor="login_username">Username:</label>
            <input type="text" id="login_username" name='username' onChange={handleUsernameChange} onKeyDown={handleKeyDown}/>
            <label htmlFor="login_password">Password:</label>
            <input type="password" id='login_password' name='password' onChange={handlePasswordChange} onKeyDown={handleKeyDown}/>
            <fieldset className='login_dialog_check'>
                <CheckBox checked={stay_logged_in} onClick={handleStayLoggedInChange} label={'Stay logged in'}/>
            </fieldset>
            <fieldset className='login_dialog_button_container form_fieldset'>
                <input type="button" value="Submit" className='active' onClick={handleSubmit}/>
            </fieldset>
        </form>
    </>

    return (<div className='login_container unselectable' style={{'--mainColor': '89, 131, 202'}}>
        <Dialog 
        title={title} 
        content={content}
        no_bg={true}
        />
        <div className="login_bg"></div>
    </div>)
}

export default Login