import { Editor, Transforms, Element as SlateElement } from 'slate'

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

export const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

export const toggleMarkValue = (editor, type, color) => {
	const isActive = isMarkActive(editor, type)

	if (isActive) {
		Editor.removeMark(editor, type)
	} else {
		Editor.addMark(editor, type, color)
	}
}

export const isBlockActive = (editor, format, blockType = 'type') => {
	const { selection } = editor
	if (!selection) return false

	const [match] = Array.from(
		Editor.nodes(editor, {
		at: Editor.unhangRange(editor, selection),
		match: n =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			n[blockType] === format,
		})
	)

	return !!match
}
export const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		match: n =>
		!Editor.isEditor(n) &&
		SlateElement.isElement(n) &&
		LIST_TYPES.includes(n.type) &&
		!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	})
	let newProperties
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		}
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		}
	}
	Transforms.setNodes(editor, newProperties)

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

/**
 * Define custom shortcuts for example for making the text bold (CTRL+b) or italic (CTRL+i)
 */
export const textFieldKeyDown = (event, editor) => {

	if (event.key === 'Enter') {

		if (event.shiftKey) {
			event.preventDefault()
			editor.insertText('\n')
		}
		else {

			const fragment = editor.getFragment()
			
			/**
			 * remove list property when the user presses enter on an empty line
			 */
			const enterEmptyListLine = () => {

				if (fragment.length !== 1) return; //No or several lines selected

				if (fragment[0].type !== 'check-list-item' && fragment[0].children[0].type !== 'list-item') return; //Line is not part of a (check)list

				const anchor = window.getSelection().anchorNode.parentElement

				const closest_list_item = anchor.closest('.list-item'); //if cursor is inside a list item

				if (closest_list_item && closest_list_item.textContent !== '\ufeff') return; //line is empty

				const next_line = closest_list_item.nextElementSibling
				if (!!next_line && next_line.classList.contains('list-item')) return;

				event.preventDefault()

				toggleBlock(editor, fragment[0].children[0].type)
			}
			enterEmptyListLine()


			/*const enterSeveralLines = () => {

				if (fragment.length <= 1) return; // only one or no lines selected

				event.preventDefault()
			}
			enterSeveralLines()*/
		}
	}
	else if (event.key === 'Tab') {

		/**
		 * Increase or decrease indentation
		 */
		event.preventDefault()

		const current_nodes = editor.getFragment()

		const current_indentation = current_nodes[0].indentation || current_nodes[0].children[0].indentation || 0;
		let new_indentation;

		if (!event.shiftKey) {
			new_indentation = current_indentation+1;
		}
		else if (event.shiftKey && current_indentation !== 0) {
			new_indentation = current_indentation-1;
		}
		
		let newProperties = {
			indentation: new_indentation,
		}
		Transforms.setNodes(editor, newProperties)
	}
	else if (event.key === 'Backspace') {
		
		/**
		 * FIX: if backspace is pressed on empty list line, transform list to default paragraph 
		 * instead of deleting list line immediately
		 * TODO: do this only if it is the last line of a list?
		 */
		const current_nodes = editor.getFragment(),
		current_line_element = window.getSelection().anchorNode.textContent,
		current_line_length = current_line_element.length

		if (current_nodes.length === 1 && LIST_TYPES.includes(current_nodes[0].type) && (current_line_length === 0 || current_line_element === '\ufeff')) {

			event.preventDefault()

			removeList(editor);
			
		}

	}

	if (event.metaKey) {

		switch (event.key) {
			case 'b': {
				event.preventDefault()
				toggleMark(editor, 'bold')
				break
			}
			case 'i': {
				event.preventDefault()
				toggleMark(editor, 'italic')
				break
			}
			case 'u': {
				event.preventDefault()
				toggleMark(editor, 'underline')
				break
			}
			case 'x': {
				//Otherwise, a list with 0 indentation would be left if user presses ctrl+x on a normal paragraph and a subsequent list item.
				//The timer is necessary for the cutting to take place first.
				window.setTimeout(() => {
					const fragment = window.getSelection().anchorNode.parentElement;
					if ( fragment.closest('li') && !fragment.closest('ul') ) {
						removeList(editor);
					}
				}, 10)
				//window.setTimeout(() => {removeList(editor)}, 0)
				break
			}
			default:
		}
	}
}

const removeList = editor => {
	const newProperties = {
		type: 'paragraph',
	}
	Transforms.setNodes(editor, newProperties)

	Transforms.unwrapNodes(editor, {
		match: n =>
		!Editor.isEditor(n) &&
		SlateElement.isElement(n) &&
		LIST_TYPES.includes(n.type),
		split: true,
	})
}
/*const checkIfAnyListActive = editor => {
	let any_list_active = false;
	for (let i = 0; i < LIST_TYPES.length; i++) {
		if (isBlockActive(editor, LIST_TYPES[i])) {
			any_list_active = true;
		}	
	}
	return any_list_active;
}*/

export const textFieldKeyUp = (e, editor) => {

	const anchor = window.getSelection().anchorNode.parentElement

	if (e.code === 'Backspace' || e.code === 'Delete') {

		/**
		 * FIX: 
		 * if a default paragraph and a list item underneath are selected and user presses del or backspace
		 * a li without a ul/ol is being created
		 */
		if (anchor.closest('li') && !anchor.closest('ul') && !anchor.closest('ol')) {
            const newProperties = {
              type: 'paragraph',
            }
            Transforms.setNodes(editor, newProperties)
		}

		/**
		 * FIX: 
		 * if a list item and a default paragraph underneath are selected and user presses del or backspace
		 * the result is a ul / ol with a div (defailt paragraph) inside -> convert div in list item again
		 */
		const [match] = Array.from(
			Editor.nodes(editor, {
			match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n['type'] === 'paragraph',
			})
		)
		if (match && (anchor.closest('ul') || anchor.closest('ol'))) {
			const block = { type: 'list-item', children: [] }
			Transforms.wrapNodes(editor, block)
		}
	}
	else if (e.code === 'Enter') {

		/**
		 * FIX:
		 * if a user presses enter at the end of a link, the new line also becomes a link
		 * -> make the new line a normal paragraph
		 */
		if (!anchor.innerHTML.length || anchor.textContent === '\ufeff') {
			Transforms.unwrapNodes(editor, {
				match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n.type === 'link',
				split: true,
			})
		}
		
	}
}