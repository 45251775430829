import { FiImage, FiTrash2 } from "react-icons/fi"
import { CgArrowsExpandLeft } from 'react-icons/cg'
import {Transforms} from 'slate'
/*import {
    Transforms,Editor,Element as SlateElement,
} from 'slate'*/
import { useSlateStatic, ReactEditor, useSelected, useFocused } from "slate-react"
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'
/*import { isBlockActive } from "./CustomEditor"
import { useState } from "react"*/

export const withImages = editor => {
    const { insertData, isVoid } = editor
  
    editor.isVoid = element => {
      return element.type === 'image' ? true : isVoid(element)
    }
  
    editor.insertData = data => {
      const text = data.getData('text/plain')
      const { files } = data
  
      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader()
          const [mime] = file.type.split('/')
  
          if (mime === 'image') {
            reader.addEventListener('load', () => {
              const url = reader.result
              insertImage(editor, url)
            })
  
            reader.readAsDataURL(file)
          }
        }
      } else if (isImageUrl(text)) {
        insertImage(editor, text)
      } else {
        insertData(data)
      }
    }
  
    return editor
  }
  
  export const insertImage = (editor, url) => {
    const text = { text: '' }
    const image = { type: 'image', url, size: 300, children: [text] }
    Transforms.insertNodes(editor, image)
  }
  
  export const Image = ({ attributes, children, element, style }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    //const [image_width, changeImageWidth] = useState(element.size || 300)
  
    const selected = useSelected()
    const focused = useFocused()

    let startX;

    const initDrag = (e) => {
      startX = e.clientX;
      document.documentElement.addEventListener('mousemove', doDrag, false);
      document.documentElement.addEventListener('mouseup', stopDrag, false);
    }
   
    const doDrag = (e) => {
      //console.log(e);
      //changeImageWidth(image_width + e.clientX - startX);
      const new_size = element.size + e.clientX - startX
      const path = ReactEditor.findPath(editor, element)
      const newProperties = {
        size: new_size,
      }
      Transforms.setNodes(editor, newProperties, { at: path })
    }
   
    const stopDrag = () => {
      document.documentElement.removeEventListener('mousemove', doDrag, false);    
      document.documentElement.removeEventListener('mouseup', stopDrag, false);
    }

    return (
      <div {...attributes}>
        {children}
        <div
          contentEditable={false}
          className={"image-container " + (selected && focused ? 'selected' : '') + (' image-container-align-'+style.textAlign)}
          style={{width: element.size+'px'}}
        >
          <img
            src={element.url}
            alt=''
          />
          <button
            className='delete-image-button'
            onMouseDown={() => Transforms.removeNodes(editor, { at: path })}
          >
            <FiTrash2 />
          </button>
          <div className="resizer" onMouseDown={initDrag}><CgArrowsExpandLeft /></div>
        </div>
      </div>
    )
  }
  
  export const InsertImageButton = ({changeImageDialogVisibility, children}) => {
    const editor = useSlateStatic()
    return (
      <button
        className={'menu_button button-image'}
        onMouseDown={() => toggleImage(editor, changeImageDialogVisibility)}
      >
        <FiImage />
        {children}
      </button>
    )
  }

  const toggleImage = (editor, changeImageDialogVisibility, image) => {

    /*const url = window.prompt('Enter the URL of the image:')
    if (url && !isImageUrl(url)) {
        alert('URL is not an image')
        return
    }
    url && insertImage(editor, url)*/

    changeImageDialogVisibility(true)

  }

  /*const isImageActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
    })
    return !!link
  }*/
  
  const isImageUrl = url => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext)
  }