import React, {useState, useMemo} from 'react'
import { /*FiChevronDown,*/ FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Calendar = ({content, openCategory, openTab}) => {

    const day_name = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"],
    month_name_short = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    date = new Date(),
    current_year = date.getFullYear(),
    current_month = date.getMonth(),
    today = date.getDate()
    //weekday = date.getDay()

    const [month, setMonth] = useState(date.getMonth())
    const year = useMemo(() => yearFromMonth(current_year, month), [current_year, month]);

    //console.log(month);

    //get last days of last month (e.g. [29, 30, 31] if current month starts on thursday = 4th of week)
    let first_day = new Date(current_year, month, 1).getDay()-1;
    if (first_day === -1) first_day = 6; //fix for sunday being -1
    const last_month_length = daysInMonth(month-1, current_year)
    let last_month_array = Array(first_day).fill().map((_, i) => [last_month_length-(first_day-i-1), month-1, []]);

    //get all days of current month
    const month_length = daysInMonth(month, current_year)
    let current_month_array = Array(month_length).fill().map((_, i) => [i+1, month, []]);

    //get first days of next month
    var lastDay = new Date(date.getFullYear(), month + 1, 0).getDay();
    if (first_day <= 4 && lastDay > 0) lastDay -= 7; //fix for month only taking up five weeks and not ending with a sunday
    let next_month_array = Array(7-lastDay).fill().map((_, i) => [i+1, month+1, []]);

    let day_array = [...last_month_array, ...current_month_array, ...next_month_array]

    assignItemsToDays(day_array, content, year, month+1, first_day, last_month_length, month_length)

    const current_month_name = month_name[monthNumber1to12(month)]

    return (
        <div className='calendar_container unselectable'>
            <div className="calendar_title">
                <div className="calendar_title_arrow" onClick={() => setMonth(month-1)}><FiChevronLeft /></div>
                <div className="calendar_title_arrow" onClick={() => setMonth(month+1)}><FiChevronRight /></div>
                <div className="calendar_title_text">{current_month_name+' '+year}</div>
                <div className="calendar_title_spacer"></div>
                {/*<div className="calendar_title_switch_view">
                    <div className="calendar_title_select_container">
                        <select name="calendar_title_select" id="calendar_title_select" className='calendar_title_select'>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                        </select>
                        <div className="calendar_title_select_arrow"><FiChevronDown /></div>
                    </div>
                </div>*/}
            </div>

            <div className="calendar_day_names">
                    {day_name.map(d => <div className='calendar_day_name' key={'day_'+d}>{d}</div>)}
            </div>

            <div className="calendar_content">

                <div className="calendar_days">
                    {day_array.map(c => {

                        const d = c[0], m = c[1],
                        is_current_month = m === month ? ' current_month' : '',
                        is_today = d === today && m === current_month ? ' today' : '',
                        display_current_month = d === 1 ? month_name_short[monthNumber1to12(m)] : '';

                        return <div className={'calendar_day'+is_current_month+is_today} key={'calendar'+m+d}>
                            <div className='calendar_day_title'>
                                <span className='calendar_current_month'>{display_current_month} </span>
                                {d}
                            </div>
                            {c[2].map(entry => {
                                const i = entry[0], j = entry[1];
                                const item = content[i].content[j]
                                return <div className='calendar_entry' key={'calendar_entry_'+i+'_'+j} style={{'--categoryColor': '#eabe39'}} onClick={() => {openCategory(['category', i]); openTab(j)}}>
                                    <div className="calendar_entry_color category_color"></div>
                                    <div className="calendar_entry_title">{item.title}</div>
                                </div>
                            })}
                        </div>
                    })}
                </div>

            </div>
        </div>
    )
}

export default Calendar

const daysInMonth = (month, year) => {
    return new Date(year, month+1, 0).getDate();
}

const monthNumber1to12 = (month) => {
    //get current month's number from 1 to 12, even if last or next year
    let current_month_name_number = month%12;
    if (current_month_name_number < 0) current_month_name_number = 12+current_month_name_number;
    return current_month_name_number;
}

const yearFromMonth = (year, month) => {
    let current_year = year;
    if (month < 0) current_year--; //month in last year
    else if (month > 11) current_year++; //month in next year
    return current_year;
}

const splitDateString = (date) => {
    return [+date.substring(8,10),+date.substring(5,7),+date.substring(0,4)]
}

const assignItemsToDays = (day_array, content, year, month, first_day, last_month_length, current_month_length) => {

    for (let i = 0; i < content.length; i++) {
        const category = content[i];

        for (let j = 0; j < category.content.length; j++) {
            const item = category.content[j];

            if (item.date) {
                const split_date = splitDateString(item.date);

                const current_month = monthNumber1to12(month) || 12; //Set the variable to 12 instead of 0 (for december)

                if (year === split_date[2] && current_month === split_date[1]) { //Due date of item in current month
                    let day_position = split_date[0] + first_day - 1;
                    day_array[day_position][2].push([i,j]);
                }
                else if ( (year === split_date[2] && current_month-1 === split_date[1]) || (year-1 === split_date[2] && split_date[1] === 12 && month === 1) ) { //Due date of item in last month
                    let day_position = first_day - (last_month_length - split_date[0]) - 1;
                    if (day_position >= 0) {
                        day_array[day_position][2].push([i,j]);
                    }
                }
                else if ( (year === split_date[2] && current_month+1 === split_date[1]) || (year+1 === split_date[2] && split_date[1] === 1 && current_month === 12) ) { //Due date of item in next month
                    let day_position = first_day + current_month_length + split_date[0] - 1;
                    if (day_position >= 0 && day_position < day_array.length) {
                        day_array[day_position][2].push([i,j]);
                    }
                }
                //tag, monat und jahr von item mit day_array vergleichen; 
                //nachsehen, ob vorhanden und dann dort an der 4. Stelle im array einfügen 
            }
        } 
    }
}