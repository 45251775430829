import React from 'react'
import { FiPlus } from 'react-icons/fi';
import { BiRename, BiTrash } from 'react-icons/bi'
import Dialog from './Dialog'
import CheckBox from '../textfield/CheckBox';

const SettingsDialog = ({changeSettingsDialogVisibility, settings, changeSettings, content, editContent}) => {

    const addCategory = () => {
        
        /**
         * Find the highest id in current content array.
         * Since users can change category order, content[content.length-1].id does not have to be the highest id -> two categories woth the same id could be created.
         */
        const getId = () => {

            let id = 0;

            for (let i = 0; i < content.length; i++) {
                const category = content[i];
                
                if (category.id > id) {
                    id = category.id;
                }
            }

            return id+1;

        }

        const new_category = {
			"title": 'New Category',
			"id": getId(),
			"color": '#5983CA',
			"content": [],
			"collapsed": false,
		}

        editContent([...content, new_category])

    }

    const removeCategory = (id) => {
        editContent(old_content => {

            return old_content.filter((_, i) => i !== id)
    
        })
    }

    const renameCategory = (id) => {
        const new_name = prompt('Please enter a new name:');

        if (!new_name || !new_name.length) return;

        let new_categories = [...content]

        new_categories[id].title = new_name

        editContent(new_categories);
    }

    const changeSpellCheck = () => {
        changeSettings({...settings, spellCheck: !settings.spellCheck})
    }

    function changeMainColor(e) {
    
        const color = e.target.value
        const r = parseInt(color.substr(1,2), 16)
        const g = parseInt(color.substr(3,2), 16)
        const b = parseInt(color.substr(5,2), 16)
    
        const rgb = r+', '+g+', '+b
    
        changeSettings({...settings, mainColor: rgb})
    }

    const changeCategoryColor = (e, i) => {

        const color = e.target.value

        let new_content = content;

        content[i].color = color;

        editContent(new_content)
    }

    const changeTrashActivation = () => {
        changeSettings({...settings, activateTrash: !settings.activateTrash})
    }

    const title = <div className='settings_title'>
        Settings
    </div>

    const main_content = <>
        <form className='settings_dialog_form'>
            <fieldset>
                <legend>Main Color</legend>
                <input type="color" defaultValue={rgbToHex(settings.mainColor)} className="main_color_picker color_picker" onChange={changeMainColor}/>
            </fieldset>
            <fieldset>
                <legend>Deleting Items</legend>
                <CheckBox checked={settings.activateTrash} onClick={changeTrashActivation} label={'Activate Recycle Bin'}/>
            </fieldset>
            <fieldset>
                <legend>Spellchecking</legend>
                <CheckBox checked={settings.spellCheck} onClick={changeSpellCheck} label={'Activate Spellchecking'}/>
                <CheckBox checked={settings.autoCorrect} onClick={changeSpellCheck} label={'Activate Autocorrect'}/>
            </fieldset>
            <fieldset className="category_list form_fieldset">
                <legend>Categories</legend>
                {content.map((c, i) => 
                    <div className={"settings_category settings_category_"+c} key={'s_cat_'+c.id}>
                        <input type="color" defaultValue={c.color} className="category_class_picker color_picker" onChange={e => changeCategoryColor(e, i)}/>
                        <div className="settings_category_title">{c.title}</div>
                        <div className="settings_category_tools">
                            <div onClick={() => renameCategory(i)}><BiRename /></div>
                            <div onClick={() => removeCategory(i)}><BiTrash /></div>
                        </div>
                    </div>
                )}
                <div className="add-category" onClick={addCategory}><FiPlus /></div>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={main_content} 
        changeDialogVisibility={changeSettingsDialogVisibility}
        close_button={true}
        />
    )
}

export default SettingsDialog

function rgbParameterToHex(c) {
    let hex = (+c).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}
function rgbToHex(color) {
    const main_color = color.replaceAll(' ','').split(',')
    const hex = "#" + rgbParameterToHex(main_color[0]) + rgbParameterToHex(main_color[1]) + rgbParameterToHex(main_color[2]);
    return hex
}