import React from 'react'
import { FiCheck } from 'react-icons/fi'

const CheckBox = ({checked, onClick, label}) => {

    return (<div className={'check_container checked_'+!!checked} onClick={onClick}>
        <span>
            <div className="checkbox"></div>
            <div 
                className="checkmark"
            ><FiCheck /></div>
        </span>
        <span className={'label_check'}>
            {label}
        </span>
    </div>)
}

export default CheckBox