import React from 'react'
import SideBarTabs from './SideBarTabs'
import MainContainer from './TextFieldContainer'
import { CategoryTitle } from './HomeScreen'

const CategoryView = ({content, editContent, current_tab, openTab, changeMobileTabVisibility, settings, changeSettings, current_category, changeChangesStatus}) => {

    /*if (!content.length) {
        return <div>Add New Tab</div>
    }*/

    const no_content_class = content[current_category[1]].content.length ? '' : ' no_items';

    return (
        <><aside className={'unselectable'+no_content_class}>
            <div className="header-title sidebar-text">
                <div className='header-title-text'>{/* style={{fontSize: 18, textAlign: 'right', paddingRight: 20}}<FiSearch />todo*/}</div>
            </div>

            <div className='sidebar_category_container'>

                <CategoryTitle content={content} editContent={editContent} i={current_category[1]} openTab={openTab}/>

                <SideBarTabs 
                content={content} 
                editContent={editContent}
                current_tab={current_tab} 
                openTab={openTab}
                changeMobileTabVisibility={changeMobileTabVisibility}
                settings={settings}
                changeSettings={changeSettings}
                current_category={current_category}
                />
            </div>
        </aside>
        <MainContainer 
        content={content}
        current_tab={current_tab}
        openTab={openTab}
        editContent={editContent}
        //changeLoginStatus={changeLoginStatus}
        //unsaved_changes={unsaved_changes}
        changeChangesStatus={changeChangesStatus}
        changeMobileTabVisibility={changeMobileTabVisibility}
        settings={settings}
        //changeSettings={changeSettings}
        current_category={current_category}
        /></>
    )
    }

export default CategoryView