import React from 'react'
import { Item } from './SideBarTabs'
import { FiChevronDown, FiChevronUp, FiPlus, /*FiSearch*/ } from 'react-icons/fi'
import { addItem } from './functions.js'
import { DnDItem } from './DragAndDrop'
import { DragPositionIndicator } from './DragAndDrop'
import Button from './Button'

const HomeScreen = (props) => {    

    return (
        <div className='homescreen unselectable'>
            <div className="homescreen_header">
                {/*<div className="homescreen_search">
                    <div className="homescreen_search_icon"><FiSearch /></div>
                    <div className="homescreen_search_input_container">
                        <input type='text' placeholder='Search' className='homescreen_search_input'/>
                    </div>
                </div>*/}
            </div>
            <div className="homescreen_category_container">

                {props.content.map((c, i) => <DnDItem 
                key={'homescreen_category_'+c.id} 
                i={props.current_category} j={i} 
                group={'homescreen'}
                total_items={props.content.length}
                props={props}>
                    <div 
                    className="homescreen_category" 
                    category={i}>
                        <div className="homescreen_category_content">
                            <CategoryTitle 
                            {...props}
                            i={i} show_buttons_if_no_items/>
                            <div className={"homescreen_category_items_hidden homescreen_category_items_hidden_"+c.collapsed}>
                                <Items 
                                {...props}
                                i={i}/>
                            </div>
                        </div>
                    </div>
                </DnDItem>)}

                {!props.content.length && <div className='empty_message'>
                    Currently there are no categories for this account. <br /> Click of the following button to add one.
                    <Button icon={<FiPlus />} active>Add Category</Button>
                </div>}

            </div>
        </div>
    )
}

export default HomeScreen

const Items = (props) => {

    const openTab = (item, num) => {
        props.openTab(num)
        props.openCategory(['category', props.i])
    }

    let items_to_display = props.content[props.i].content

    const item_count = items_to_display.length

    if (item_count === 0) {
        return <>
            <div className='homescreen_category_empty'>
                <Button icon={<FiPlus />} active onClick={() => {addItem(props.content, props.editContent, props.i, 0, props.openCategory, props.openTab)}}>Add Item</Button>
            </div>
            <DragPositionIndicator group='sidebar' i={props.i} j={0} end={''}/>
        </>
    }

    const id = props.i;

    return items_to_display.map((item,j) => <DnDItem key={'item_'+id+j} i={id} j={j} group='sidebar' total_items={item_count} props={props}>
        <Item 
        props={{...props, openTab: () => openTab(item, j)}} 
        item={item}
        i={id} 
        j={j}/>
    </DnDItem>)
}

const changeItemVisibility = (collapsed, i, content, editContent) => {

    let new_content = [...content]

    new_content[i].collapsed = !collapsed;

    editContent(new_content)

}

export const CategoryTitle = ({content, editContent, i, openCategory, openTab, current_category, changeMobileTabVisibility, show_buttons_if_no_items}) => {

    const category = content[i]
    const category_item_count = !!category.content.length || show_buttons_if_no_items;

    /*const titleClick = () => {

        if (window.innerWidth <= 810) return;
        if (!current_category || current_category[0] === 'category') return;

        openCategory(['category', i])
        
    }*/

    return (
        <div className='category_title'>
            <div className="category_title_color" style={{backgroundColor: category.color}}></div>
            <div className="category_title_text" /*onClick={titleClick}*/>{category.title}</div>
            {/*<div className="category_title_spacer"></div>*/}
            {category_item_count && <>
                <div className="category_title_button" onClick={() => addItem(content, editContent, i, category.content.length, openCategory, openTab, changeMobileTabVisibility)}><FiPlus /></div>
                <div className="category_title_button" onClick={() => changeItemVisibility(category.collapsed, i, content, editContent)}>{category.collapsed ? <FiChevronUp /> : <FiChevronDown />}</div>
            </>}
        </div>
    )

}