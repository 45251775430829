import React from 'react'
import Dialog from './Dialog'

const AboutDialog = ({changeDialogVisibility}) => {
    const title = <>About</>

    const date = new Date();
    const year = date.getFullYear();

    const dialog_content = <div className='about_container'>
        <img src='/logo_blue_bg.svg' className='about_logo' alt=''/>
        <div className='about_text'>
            <b>todo</b> <br />
            2022 - {year} <br />
            by Benjamin Trnoska <br />
            <a href='https://www.btdesign.at' className='about_link'>www.btdesign.at</a>
        </div>
    </div>

    return (
        <Dialog 
        title={title} 
        content={dialog_content} 
        changeDialogVisibility={changeDialogVisibility}
        close_button={true}
        />
    )
}

export default AboutDialog