import React, { useState } from 'react'
import { FiRotateCcw, FiToggleRight, FiTrash } from 'react-icons/fi'
import { sideBarItemContent } from './SideBarTabs'
import Button from './Button'
import { EmptyTrashDialog, DeleteTrashItemDialog } from './dialogs/TrashDialogs'

const Trash = (props) => {

    const [empty_trash_dialog_visible, emptyTrashDialogVisibility] = useState(false)
    const [delete_trash_item_dialog_visible, deleteTrashItemDialogVisibility] = useState(false)

    return (
        <div className='trash unselectable'>
            <div className="trash_header">
                <div className="trash_title">Recycle Bin</div>
                <div className="trash_empty_button">
                    {!!props.trash.length && <Button icon={<FiTrash />} onClick={() => emptyTrash(props.trash, emptyTrashDialogVisibility)}>Empty</Button>}
                </div>
            </div>
            <div className="trash_content">
                <TrashContent {...props}/>
            </div>
            {empty_trash_dialog_visible && <EmptyTrashDialog 
            editTrash={props.editTrash}
            changeDialogVisibility={emptyTrashDialogVisibility}/>}

            {delete_trash_item_dialog_visible && <DeleteTrashItemDialog 
            changeDialogVisibility={deleteTrashItemDialogVisibility}/>}
        </div>
    )
}

export default Trash

const emptyTrash = (trash, emptyTrashDialogVisibility) => {
        emptyTrashDialogVisibility(true);
}

const TrashContent = (props) => {

    const activateTrash = () => {
        props.changeSettings({...props.settings, activateTrash: true})
    }

    if (props.trash.length) {
        return <TrashItems {...props}/>
    }
    else {
        if (props.settings.activateTrash) {
            return <div className='empty_message'>The recycle bin is currently empty.</div>
        }
        else {
            return <div className='empty_message'>
                <div className='button_description'>The recycle bin is currently deactivated. Press the following button to activate it:</div>
                <Button icon={<FiToggleRight />} active onClick={activateTrash}>Activate Recycle Bin</Button>
            </div>
        }
    }
}

const TrashItems = ({trash, content, editTrash, editContent}) => {

    const deletePermanently = i => {
        let new_trash = [...trash];
        new_trash.splice(i, 1)
        editTrash( new_trash )
    }

    const restoreItem = (t, i) => {

        //remove properties from the item that are only necessary for trash
        let item_to_restore = {...t}
        delete item_to_restore.category
        delete item_to_restore.j

        //insert restored item in content at its old position
        let new_content = [...content]
        new_content[t.category].content.splice(t.j, 0, item_to_restore)
        editContent(new_content)

        //remove item from trash
        let new_trash = [...trash]
        new_trash.splice(i, 1)
        editTrash(new_trash)
    }

    return trash.map((t, i) => {
        return <div className='item trash_content_item box two_icons' key={'trash_item_'+i}>
            <div className="item_text">
                {sideBarItemContent(i, t, content)}
            </div>
            <div className="sidebar_tab_options">
                <div className="sidebar_tab_" onClick={() => restoreItem(t, i)}><FiRotateCcw /></div>
                <div className="sidebar_tab_" onClick={() => deletePermanently(i)}><FiTrash /></div>
            </div>
        </div>
    })
}