import React, {useState} from 'react'
import { changeWindowLocation } from './functions'

export const DnDItem = ({children, i, j, group, total_items, props}) => {

    const [item_pos_y, changePosY] = useState(0)
    const [item_pos_x, changePosX] = useState(0)

    /**
     * When user touches an item, both the mousedown and the touchstart event get executed.
     * For mousedown, touched is false; for touchstart true
     */        
    let touched = false;
    let moved = false, focused_item_id//, touch_move_pos_backup = -1;
    let closest_indicator, closest_indicator_distance, initial_cursor_pos_y, initial_cursor_pos_x, target;

    /**
     * Initiate mousedown and -move functions for desktop
     * @param {Event} e - Event
     */
    const itemMouseDown = (e) => {

        if (e.button !== 0) return; //Not left button

        if (e.target.closest('.no_drag_action')) return;

        const y = e.clientY;
        const x = e.clientX;

        target = e.target.closest('.dnd_item');
        focused_item_id = +target.id.replace( /^\D+/g, '');
        initial_cursor_pos_y = y - target.offsetTop;
        initial_cursor_pos_x = x - target.offsetLeft;

        /**
         * Check if the mouse has been moved at least 20px in one direction since mousedown.
         * Otherwise, the drag function would be triggered even if the user just clicks on the item to open it, causing it to move around.
         */
        const checkMove = e => {
            if (Math.abs(y - e.clientY) > 20 || Math.abs(x - e.clientX) > 20) {
                document.addEventListener('mousemove', itemMove)
                document.removeEventListener('mousemove', checkMove)
            } 
        }

        const mouseUp = e => {
            document.removeEventListener('mousemove', checkMove)
            document.removeEventListener('mouseup', mouseUp)
            itemUp(e)
        }

        document.addEventListener('mousemove', checkMove)
        document.addEventListener('mouseup', mouseUp)
    }

    /**
     * Initiate mousedown and -move functions for touch devices
     * @param {Event} e - Event
     */
    const itemTouchDown = (e) => {

        touched = true;
        
        document.addEventListener('touchend', itemUp)

        let touch_position = e.changedTouches[0].clientY;
        target = e.target.closest('.dnd_item');
        focused_item_id = +target.id.replace( /^\D+/g, '');
        initial_cursor_pos_y = touch_position - target.offsetTop;
        initial_cursor_pos_x = touch_position - target.offsetLeft;

        /*//Get the furthest position the item has been moved
        const saveMouseMovement = e => {
            if (e.changedTouches && e.changedTouches[0].clientY > touch_move_pos_backup) {
                touch_move_pos_backup = Math.abs(e.changedTouches[0].clientY);
            }
        }
        document.addEventListener('touchmove', saveMouseMovement)

        //Maybe? durchgehendtouch = true;
        //document.addEventListener('touchend', () => {durchgehendtouch = false})

        //After 1 Second: If the item hasn't been moved far or not at all, and is still being touched, start selection function
        window.setTimeout(() => {

            document.removeEventListener('touchmove', saveMouseMovement)

            const drag_distance = Math.abs(touch_position - touch_move_pos_backup)

            console.log(touched);

            //See line 61: document.removeEventListener('touchend', () => {durchgehendtouch = false})

            if ((touch_move_pos_backup === -1 || drag_distance <= 10) && touched) {
                /*document.addEventListener('touchmove', itemMove)
                document.addEventListener('scroll', handleMobileScroll)* /
                document.querySelector('.main-container').classList.add('mobilemoveitem');
            }
        }, 1000)*/

        const stopLongTouchTimer = () => {
            //stops short touches from firing the event
            if (timer)
                clearTimeout(timer); // clearTimeout, not cleartimeout..
        }
        document.addEventListener('touchend', stopLongTouchTimer)

        const onLongTouch = () => {
            document.querySelector('.main-container').classList.add('mobilemoveitem');
        }
        let timer = setTimeout(onLongTouch, 1000);

    }

    const itemMove = (e) => {

        target.scrollIntoView()

        //Find out the right y and x position
        let y = e.clientY || e.changedTouches[0].clientY //Touch device: access to clientY is different
        const top = y - initial_cursor_pos_y;

        let x = e.clientX || e.changedTouches[0].clientX //Touch device: access to clientX is different
        const left = x - initial_cursor_pos_x;

        //Activate necessary styling (position: absolute; ...)
        if (!moved) {
            target.classList.add('item_dragging')
            const initial_indicator = document.getElementById('drag_position_indicator_'+group+'_'+i+'_'+focused_item_id) //Indicator directly above the item
            initial_indicator.classList.add('no-transition', 'active')
            window.setTimeout(() => {
                initial_indicator.classList.remove('no-transition')
            }, 200)
        }

        moved = true;
        
        //Code for moving the item

        const drag_indicators = document.querySelectorAll('.drag_position_indicator[data-group='+target.dataset['group']+']:not(#drag_position_indicator_'+group+'_'+i+'_'+focused_item_id+')')

        //Loop through all drag indicators and find the one closest to the mouse cursor
        for (let i = 0; i < drag_indicators.length; i++) {
            const current_indicator = drag_indicators[i];
            const indicator_top = drag_indicators[i].getBoundingClientRect().top
            const indicator_left = drag_indicators[i].getBoundingClientRect().left

            const current_cursor_position_y = Math.abs(indicator_top-y)
            const current_cursor_position_x = Math.abs(indicator_left-x)

            const pythagoras = Math.hypot( current_cursor_position_y, current_cursor_position_x )
            
            if (!closest_indicator_distance || pythagoras < closest_indicator_distance) {
                closest_indicator_distance = pythagoras
                closest_indicator = current_indicator
            }
        }
    
        //Reset varible that is storing the nearest indicator during the for-loop.
        closest_indicator_distance = undefined;

        //If there is already an active indicator and this indicator is not the nearest one any more, change active class
        const active_indicator = document.querySelector('.drag_position_indicator.active')
        if (active_indicator && active_indicator !== closest_indicator) {
            active_indicator.classList.remove('active')
            closest_indicator && closest_indicator.classList.add('active')
        }
        else if (!active_indicator) { //Dragging has just started and there is no active item yet
            closest_indicator.classList.add('active')
        }
        //Change the actual position of the dragged item by altering the corrisponding variable in state.
        changePosY(top) 
        changePosX(left) 
    }

    const itemUp = e => {

        document.removeEventListener('mousemove', itemMove)
        //document.removeEventListener('mouseup', itemUp) //Already gets removed in itemMouseDown

        if (touched) {
            document.removeEventListener('touchmove', itemMove)
            document.removeEventListener('touchend', itemUp)
        }

        const active_drag_element = document.querySelector('.drag_position_indicator.active')

        //FOR SPECIFIC PARTS OF todo
        switch (group) {
            case 'sidebar':
                moveItem(moved, touched, target, props, active_drag_element, e)
                break;
            case 'homescreen':
                moveCategory(moved, props, active_drag_element, e)
                break;
            default:
        }

        if (!active_drag_element) return;
        active_drag_element.classList.remove('active')
        closest_indicator_distance = undefined;

        const item_dragging = document.querySelector('.item_dragging')
        if (item_dragging) {
            item_dragging.classList.remove('item_dragging')
        }
        changePosY(0)
        changePosX(0)
        touched = false;
        moved = false;
    }

    const item_style = {top: item_pos_y, left: item_pos_x}

    return (<>
        <DragPositionIndicator group={group} i={i} j={j} end={''}/>
        <div 
        className='dnd_item'
        onMouseDown={itemMouseDown}
        onTouchStart={itemTouchDown}
        style={item_style}
        data-drag-id={j}
        data-category={i}
        data-group={group}>
            {children}
        </div>
        {total_items === j+1 && <DragPositionIndicator group={group} i={i} j={j} end={'end'}/>}
    </>)
}

export const DragPositionIndicator = ({group, i, j, end}) => <div className={"drag_position_indicator "+end} id={'drag_position_indicator_'+group+'_'+i+'_'+j} data-group={group}></div>


//FOR SPECIFIC PARTS OF todo

/**
 * Change the item's position in the array
 */
const moveItem = (moved, touched, target, props, active_drag_element, e) => {

    if (!e.target.closest || !e.target.closest('.item')) return; //item moved outside screen -> e.target is HTML Document

    const current_id = +e.target.closest('.item').id.match(/\d+/g)[0];

    //Only if an item has been clicked (item options buttons are excluded)
    if (!moved && e.type === "mouseup" && !e.target.closest('.sidebar_tab_options') && !e.target.closest('.dialog_bg') && !e.target.closest('.dialog')) {
        props.openTab(current_id)
        props.changeMobileTabVisibility(true)
        if (touched) {
            changeWindowLocation(current_id)
        }

        if (props.current_category[0] === 'home') {
            const category = e.target.closest('.homescreen_category')
            if (category) {
                props.openCategory(['category', +category.getAttribute("category")])
            }
        }
    }

    //for mobile: change url to .../#id to enable mobile back button to close textfield
    //if (touched && !e.target.closest('.sidebar_tab_options') && e.type === 'touchend') {
        //console.log("TabVisibility");
        //props.changeMobileTabVisibility(true)
        //changeWindowLocation(current_id)
    //}

    if (!moved) return;

    let new_id = active_drag_element.id.match(/\d+/g);

    const old_category = target.dataset.category

    if ( !(+new_id[0] === +old_category && +new_id[1] === current_id) ) { //Item has been moved

        if (active_drag_element.classList.contains('end')) new_id[1]++;

        changeItemPositionInArray(props, new_id, current_id, old_category)

    }
}

/**
 * Code for changing the item's position in content array
 */
export const changeItemPositionInArray = (props, new_id, current_id, old_category) => {

    const element = props.content[old_category].content[current_id]

    const new_content = [...props.content]

    new_content[new_id[0]].content.splice(new_id[1], 0, element); //Add to new category

    let correction = 0
    if (new_id[1] <= current_id && new_id[0] === old_category) correction = 1;

    new_content[old_category].content.splice(current_id+correction, 1); //Remove from old category

    props.editContent(new_content)

    if (props.current_category[0] !== 'home') {
        props.openTab(new_id[1]-1+correction);
    }
}

/**
 * Change the category's position in the array (homescreen)
 */
const moveCategory = (moved, props, active_drag_element, e) => {

    if (!moved) return;

    const new_content = [...props.content]

    const category_element = e.target.closest('.homescreen_category');

    if (!category_element) return;

    const current_id = +category_element.getAttribute("category");

    if (!active_drag_element) return;

    let new_id = +active_drag_element.id.match(/\d+/g)[0];

    const element = props.content[current_id]

    new_content.splice(new_id, 0, element) //Add to new category

    let correction = 0
    if (new_id <= current_id) correction = 1;

    new_content.splice(current_id+correction, 1); //Remove from old category

    props.editContent(new_content)

}