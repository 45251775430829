import React from 'react'
import { convertToSaveString } from './functions'
import TextField from './TextField'

const TextFieldContainer = (props) => {

    /*switch (props.settings.layout) {
        case 0:*/
            //let current_tab = props.content[props.current_tab]
            let current_tab_title = '';
            let current_category_content = props.content[ props.current_category[1] ].content;
            if (current_category_content.length) {
                current_tab_title = convertToSaveString(props.content[ props.current_category[1] ].content[ props.current_tab ].title)
            }
            else return;
            
            //console.log(current_tab_title);

            return (
                <main>
                    <TextField 
                    {...props}
                    key={"txtfldcntnr"+current_tab_title+props.current_tab+props.current_category[1]}/>
                </main>
            )
        /*case 1:
            return (
                <main>
                    <CompactView 
                    {...props}/>
                </main>
            )
        default:
            return <></>;
    }*/
}

export default TextFieldContainer