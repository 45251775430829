import React, { useState } from 'react'
import { FiCalendar, FiCheck, FiChevronDown, FiChevronUp, FiMoreHorizontal, FiTag, FiPlus, FiTrash } from 'react-icons/fi'
import { DropDownMenu } from './MenuBar'
import { ChangeDateDialog, ChangeCategoryDialog } from './dialogs/SideBarTabsDialogs'
import { LIST_TYPES } from './textfield/CustomEditor'
import { Slate, withReact } from 'slate-react'
import { createEditor } from 'slate'
import { EditableContainer } from './TextField'
import { withInlines } from './textfield/Link'
import { DnDItem, changeItemPositionInArray } from './DragAndDrop'
import { displayPrettyDate } from './functions'
import Button from "./Button"
import { addItem } from "./functions"

const DisplaySortedItems = (props) => {

    let content_array = {}
        
    props.content.forEach((entry, i) => {

        let date
        if (entry.date) {
            date = entry.date
        } 
        else {
            date =  0
        }

        if ( !content_array[date] ) {
            content_array[date] = [[entry,i]]
        }
        else {
            content_array[date].push([entry,i])
        }
    })
    
    const id = props.current_category[1]
    const items = props.content[id].content

    return items.map((item,j) => <DnDItem key={'item_'+id+j} i={id} j={j} group='sidebar' total_items={items.length} props={props}>
        <Item 
        props={props} 
        item={item}
        i={id} 
        j={j}/>
    </DnDItem>)
}

const deleteItem = (e, editContent, content, i, j, openTab, current_tab, settings) => {

    let new_content = [...content]

    let deleted_item = {...new_content[i].content.splice(j, 1)[0], category: i, j: j}

    /**
     * Don't move to recycle bin if user pressed shift or item is empty
     */
    if ( !settings.activateTrash || e.shiftKey || (!deleted_item.title.length && JSON.stringify(deleted_item.content) === '[{"type":"paragraph","children":[{"text":""}]}]')) {
        console.log(true);
        deleted_item = null;
    }

    const total_length = new_content[i].content.length;

    if (current_tab > total_length-1) {
        //If the deleted item was the selected and the last one, select the item before
        openTab(j-1 > 0 ? j-1 : 0);
    }
    else if (current_tab > j) {
        //If the selected item comes after the deleted item, the selected item's it get increased by one -> corrention
        openTab(j+1 < total_length ? j+1 : 0);
    }

    editContent(new_content, deleted_item)
}

const markDone = (editContent, content, id) => {

    const newItems = content.map((item, i) => {
        if (i === id) {
            return { ...item, done: !content[i].done} //Content of Textfield has been changed
        }
        return item;
    });
    editContent(newItems);

}

const SideBarTabs = (props) => {

    const current_category_id = props.current_category[1];
    const current_category_props = props.content[current_category_id]

    if (current_category_props.content.length) { //category has items
        return (
            <nav className={current_category_props.collapsed ? 'category_view_items_hidden': ''}>
                {DisplaySortedItems(props)}
            </nav>
        )
    }
    else {
        return <>
          <div className="category_no_items_content">
            <div className="category_no_items_text button_description">There are currently no items in this category. Press the following button to add an item:</div>
            <Button icon={<FiPlus />} active onClick={() => {addItem(props.content, props.editContent, current_category_id, 0, undefined, props.openTab)}}>Add Item</Button>
          </div>
        </>
    }
}

export default SideBarTabs

export const Item = ({props, item, i, j}) => {

    const [category_dialog_visible, changeCategoryDialogVisibility] = useState(false)
    const [date_dialog_visible, changeDateDialogVisibility] = useState(false)

    const [dropdown_visible, changeDropDownVisibility] = useState(false)

    const selected = j === props.current_tab ? 'selected ' : ' '
    const category_color = props.content[i].color
    const done = item.done ? 'item-done ' : ''
    const dropdown = dropdown_visible ? 'dropdown_visible ' : ' '

    const item_id = 'item_'+j
    const item_classname = 'item box ' + selected + done + dropdown
    const item_style = {'--categoryColor':category_color}

    const dialog_props = {
        content: props.content,
        editContent: props.editContent,
        current_category: i,
        id: j
    }

    let right = true;
    if ((i === 0 || props.current_category[0] === 'category') && window.innerWidth <= 1195) {
        right = false;
    }

    return <>
        <div 
        id={item_id}
        className={item_classname}
        style={item_style}
        >
            <div className="item_change_position no_drag_action">
                <div className="item_move_button item_move_up" onMouseUp={() => clickMobileItemMoveButton(true, i, j, props)}>
                    <FiChevronUp />
                </div>
                <div className="item_move_button item_move_down" onMouseUp={() => clickMobileItemMoveButton(false, i, j, props)}>
                    <FiChevronDown />
                </div>
            </div>
            <div className="item_text">
                {sideBarItemContent(item[1], item)}
            </div>
            <div className="sidebar_tab_options">
                <DropDownMenu icon={<FiMoreHorizontal />} class_name={' item_dropdown_menu'} id={'item_dd_'+i+'_'+j} right={right} no_arrow changeDropDownVisibility={changeDropDownVisibility}>
                    <div className='item_dd_entry' onMouseDown={() => changeDateDialogVisibility(true)}><FiCalendar /><div className="button_text">Change Due Date</div></div>
                    <div className='item_dd_entry' onMouseDown={() => changeCategoryDialogVisibility(true)}><FiTag /><div className="button_text">Change Category</div></div>
                </DropDownMenu>
                <div className="sidebar_tab_delete" onClick={e => deleteItem(e, props.editContent, props.content, i, j, props.openTab, props.current_tab, props.settings)}><FiTrash /></div>
                <div className="sidebar_tab_check" onClick={() => markDone(props.editContent, props.content, j)}><FiCheck /></div>
            </div>
            <div className="item_category_indicator"></div>
        </div>

        {category_dialog_visible && <ChangeCategoryDialog {...dialog_props} changeDialogVisibility={changeCategoryDialogVisibility}/>}
        {date_dialog_visible && <ChangeDateDialog {...dialog_props} changeDialogVisibility={changeDateDialogVisibility}/>}
    </>
}

/**
 * Return the item's title, content, and date if existent.
 * Otherwise, return text 'No Content'.
 * @param id - id of current tab for creating a react key
 */
export const sideBarItemContent = (id, item, categories) => {
    let content = [];

    const title = item.title, date = item.date, category = item.category //category is only for items in trash

    if (category !== undefined) {
        content.push(<div className='item_preview_category' key={'id_'+id}>
            <div className='item_preview_category_icon'><FiTag /></div>
            <div className='item_preview_category_text'>{categories[category].title}</div>
        </div>)
    }
    else if (date) {
        const pretty_date = displayPrettyDate(date);
        
        content.push(<div className='item_preview_date' key={'id_'+id}>
            <div className='item_preview_date_icon'><FiCalendar /></div>
            <div className='item_preview_date_text'>{pretty_date}</div>
        </div>)
    }

    if (title) { //current item has a title
        content.push(<p className='item_preview_title' key={'it_'+id}><b>{title}</b></p>)
    }
        
    //loop through all child-leaves in the first paragraph and combine their text content for a preview
    let first_line_text = '';
    let first_line = item.content[0]

    if (first_line) {
        if (LIST_TYPES.includes(first_line.type)) {
            first_line = first_line.children[0]
        }
        for (let i = 0; i < first_line.children.length; i++) {
            const child = first_line.children[i];
            first_line_text += child.text;
        }
    }

    const ms = Date.now()
    const editor = withInlines(withReact(createEditor()))

    let content_first_line = {...item.content[0]}

    if (first_line_text.length) { //current item has a content
        //content.push(<p className='item_preview_content' key={'ic_'+id}>{first_line_text}</p>)
        content.push(<Slate editor={editor} value={[content_first_line]} key={'item_'+title+ms}><EditableContainer type={'sidebar'}/></Slate>)
    }


    return content.length ? content : <i>No Content</i>;
}

const clickMobileItemMoveButton = (up, i, j, props) => {

    //console.log(i, j);

    //console.log(props.content[i].content.length);

    let new_item_pos = j, new_category = i;
    if (up) { 
        if (j > 0) {
            new_item_pos--;
        }
        else if (j === 0 && i > 0) {
            new_category--;
            new_item_pos = props.content[new_category].content.length;
            console.log(2);
        }
    }
    else {
        if (j < props.content[i].content.length-1) {
            new_item_pos += 2;
            console.log(3);
        }
        else if (props.content[i+1]) {
            new_category++;
            new_item_pos = 0;
        }
    }

    const new_id = [new_category, new_item_pos]

    changeItemPositionInArray(props, new_id, j, i)

}