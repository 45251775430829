import React from 'react'
import { FiX } from 'react-icons/fi'

const Banner = ({content, title, className}) => {

	const closeBanner = () => {
		document.querySelector('.main-container').classList.remove(className+'_active')
	}

	return (
		<div className={'banner '+className}>
			<div className="banner_main">
				<div className="banner_title">{title}</div>
				<div className="banner_content">{content}</div>
			</div>
			<div className="banner_close_button" onClick={closeBanner}><FiX /></div>
		</div>
	)
}

export default Banner