import { useState, useCallback } from "react"
import { createEditor, Editor, Transforms, Element as SlateElement } from 'slate'
import { Slate, Editable, withReact } from "slate-react"
import Leaf from "./textfield/Leaf"
import {LIST_TYPES, textFieldKeyDown, textFieldKeyUp} from "./textfield/CustomEditor"
import MenuBar from './MenuBar';
import { FiCheck, FiX } from "react-icons/fi"
import { withHistory } from 'slate-history'
import { withInlines, LinkComponent } from './textfield/Link'
import { withImages, Image } from './textfield/Image'
import { withChecklists, CheckListItemElement } from "./textfield/CheckList"
//import CompactView from "./CompactView"
  
const TextField = (props) => {

    let initialValue = [{type: 'paragraph', children: [{ text: '' },],},]/*, current_title*/
    let category_content = props.content[props.current_category[1]].content
    let current_tab = category_content[props.current_tab]
    if (current_tab && current_tab.content.length) {
      initialValue = current_tab.content
    }

    const [editor] = useState(() => withChecklists(withImages(withInlines(withHistory(withReact(createEditor()))))))
    const [title, changeTitle] = useState(current_tab ? current_tab.title : '')
    const [textfield_focused, textfieldFocusChange] = useState(false)
    const [fontsize, changeFontSize] = useState(16)

    const editorHandleChange = (value, type) => {

      const new_fontsize = getFontSize(editor)
      new_fontsize !== fontsize && changeFontSize(new_fontsize)

      if (JSON.stringify(current_tab.content) === JSON.stringify(value)) return; //Nothing changed -> function also gets called when the cursor position changes

      const new_content_array = [...props.content];

      switch (type) {
        case 'content':
          new_content_array[props.current_category[1]].content[props.current_tab].content = value
          break;
        case 'title':
          new_content_array[props.current_category[1]].content[props.current_tab].title = value
          break;
        default:
          break;
      }

      props.editContent(new_content_array);
    }
      
    const titleKeyDown = e => {

      const input = document.querySelector('#title_input')//,
      //editable = document.querySelector('.editable')

      switch (e.code) {
        case 'Escape':
          input.blur()
          input.value = current_tab.title
          break;
        case 'Enter':
          input.blur()
          editorHandleChange(title, 'title')
          //editable.focus()
          break;
        default:
          break;
      };

    }  
    
    const closeTab = () => {

      var redirectToURL = document.URL.replace(/#.*$/, "");
      redirectToURL = redirectToURL + '#'
      window.location.href = redirectToURL;

    }


    const { insertBreak } = editor
    editor.insertBreak = () => editorInsertBreak(insertBreak, editor)
  
    return (<>
          <Slate 
          editor={editor} 
          value={initialValue} 
          onChange={value => editorHandleChange(value, 'content')}
          >
            <MenuBar
            value={props.content} 
            editContent={props.editContent}
            textfield_focused={textfield_focused}
            textfieldFocusChange={textfieldFocusChange}
            changeLoginStatus={props.changeLoginStatus}
            changeChangesStatus={props.changeChangesStatus}
            unsaved_changes={props.unsaved_changes}
            settings={props.settings}
            changeSettings={props.changeSettings}
            fontsize={fontsize}
            changeFontSize={changeFontSize}
            openTab={props.openTab}
            changeMobileTabVisibility={props.changeMobileTabVisibility}
            />
            <div className={"textfield box"}>
              <div className="textfield_scroll_container">
                  <TitleInput 
                  title={title} 
                  changeTitle={changeTitle} 
                  titleKeyDown={titleKeyDown}
                  editorHandleChange={editorHandleChange}
                  current_tab={current_tab}
                  />
                  <div className="close_textfield" onClick={closeTab}><FiX /></div>
                  <EditableContainer 
                  textfieldFocusChange={textfieldFocusChange} 
                  editor={editor} 
                  type={'main'} 
                  spellCheck={props.settings.spellCheck}
                  autoCorrect={props.settings.autoCorrect}
                  />                
              </div>
            </div>
          </Slate>
    </>)
  }

export default TextField;


export const EditableContainer = ({editor, textfieldFocusChange, type, spellCheck, autoCorrect}) => {

  const renderElement = useCallback(props => {

    const indentation = props.element.indentation || 0;

    const style = { textAlign: props.element.align, marginLeft: indentation*16+'px' }
    switch (props.element.type) {
      case 'code':
        return <CodeElement style={style} {...props} />
      case 'link':
        return <LinkComponent style={style} {...props} />
      case 'image':
        return <Image style={style} {...props} />
      case 'check-list-item':
        return <CheckListItemElement style={style} {...props} />
      case 'bulleted-list':
        return <ul style={style}>{props.children}</ul>
      case 'numbered-list':
          return <ol style={style} className="list-item">{props.children}</ol>
      case 'list-item':
          return <li style={style} className="list-item">{props.children}</li>
      default:
        return <DefaultElement style={style} {...props} />
    }
  }, [])

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />
  }, [])

  if (type === 'main') {

    return (
      <Editable
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      onKeyDown={e => textFieldKeyDown(e, editor)}
	    onKeyUp={e => textFieldKeyUp(e, editor)}
      style={{height: 'calc(100% - 77px)', wordBreak: 'break-word'}}
      onFocus={e => {e.preventDefault(); textfieldFocusChange(true)}}
      onBlur={() => textfieldFocusChange(false)}
      className='editable'
      spellCheck={!!spellCheck}
      autoCorrect={!!autoCorrect ? 'on' : 'off'}
      placeholder="Content"
      />
    )
  }
  else {

    return (
      <Editable
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      readOnly={true}
      className="sidebar_content_preview"
      />
    )

  }

}


const TitleInput = ({title,changeTitle,titleKeyDown,editorHandleChange,current_tab}) => {

  return <div className="title_input_container">
    <input
    className="title_input"
    id="title_input"
    type="text" 
    defaultValue={title}
    onChange={e => changeTitle(e.target.value)}
    onKeyDown={titleKeyDown}
    placeholder='Title'
    autoComplete="off"
    />
    <div className="input_focus_bg" onMouseDown={() => editorHandleChange(title, 'title')}></div>
    <div className="input_manage_changes input_confirm_changes box box-svg" onMouseDown={() => editorHandleChange(title, 'title')}><FiCheck /></div>
    <div className="input_manage_changes input_discard_changes box box-svg" onMouseDown={() => document.getElementById('title_input').value = current_tab.title}><FiX /></div>
  </div>

}


const CodeElement = props => {
    return (
      <pre style={props.style} {...props.attributes}>
        <code>{props.children}</code>
      </pre>
    )
}
  
const DefaultElement = props => {
    return <div style={props.style} {...props.attributes}>{props.children}</div>
}



const getFontSize = (editor) => {
  let fontsize;
  let current_elements = editor.getFragment()
  if (current_elements.length === 1 && LIST_TYPES.includes(current_elements[0].type)) { //only a list is selected
    current_elements = current_elements[0].children
  }
  for (let i = 0; i < current_elements.length; i++) {
    const element = current_elements[i];
    
    const children = element.children;

    for (let j = 0; j < children.length; j++) {
      const child = children[j];

      const size = child.size
      if (size && !fontsize) {
        fontsize = size;
      }
      else if (fontsize && fontsize !== size) {
        return undefined; //different sizes within the selection
      }
    }
  }
  return fontsize || 16;
}

const editorInsertBreak = (insertBreak, editor) => {

	/**
	 * If the user hits enter on a checked checklist item -> make the new line unselected
	 */
	const { selection } = editor

	if (selection) {
		const [checked] = Editor.nodes(editor, {
			match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				(n.type === 'check-list-item') &&
				(n.checked === true)
		})
		if(checked){
			Transforms.insertNodes(editor, {
				children: [{text: ""}],
				type: 'check-list-item',
				checked: false
			})
			return
		}
	}
	insertBreak()

}