import {
    Transforms,Editor,Range,Element as SlateElement,
} from 'slate'
import isUrl from 'is-url';
import { FiEdit3, FiLink2 } from "react-icons/fi"
import { isBlockActive } from "./CustomEditor"
import { useSlate, useSlateStatic, ReactEditor } from "slate-react"
import { MdLinkOff } from "react-icons/md";

export const withInlines = editor => {
    const { insertData, insertText, isInline } = editor
  
    editor.isInline = element =>
      ['link', 'color'].includes(element.type) || isInline(element)
  
    editor.insertText = text => {
      if (text && isUrl(text)) {
        wrapLink(editor, text)
      } else {
        insertText(text)
      }
    }
  
    editor.insertData = data => {
      const text = data.getData('text/plain')
  
      if (text && isUrl(text)) {
        wrapLink(editor, text)
      } else {
        insertData(data)
      }
    }
  
    return editor
  }
  
  export const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url)
    }
  }
  
  const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
  }
  
  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
  
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : [],
    }
  
    if (isCollapsed) {
      Transforms.insertNodes(editor, link)
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  }
  
  // Put this at the start and end of an inline component to work around this Chromium bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
  /*export const InlineChromiumBugfix = () => (
    <span
      contentEditable={false}
      style={{fontSize: 0}}
    >
      ${String.fromCodePoint(160) /* Non-breaking space * /}
    </span>
  )*/
  
  
export const LinkComponent = ({ attributes, children, element, style }) => {
    const editor = useSlateStatic()
    const url = element.url;
    return (
      <span
        {...attributes}
        href={url}
        style={style}
        className='link'
      >
        {/*<InlineChromiumBugfix />*/}
        {children}
        {/*<InlineChromiumBugfix />*/}
        <div className="link_hover_popup" contentEditable={false}>
          <a
          contentEditable={false}
          className="link_hover_popup_a unselectable"
          href={url}>
            {/*Open Link is being added as ::after-content via CSS in order to be unselectable*/}
          </a>
          <div
          className="link_hover_popup_icon"
          onClick={() => {
            const path = ReactEditor.findPath(editor, element)
            const newProperties = {
              url: prompt('Please enter a new link:',url),
            }
            Transforms.setNodes(editor, newProperties, { at: path })
          }}><FiEdit3 /></div>
          <div
          className="link_hover_popup_icon"
          onClick={() => {
            const path = ReactEditor.findPath(editor, element)
            Transforms.unwrapNodes(editor, { at: path })
          }}><MdLinkOff /></div>
        </div>
      </span>
    )
  }
  
  
  const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
  }
  
  export const ToggleLinkButton = ({changeLinkDialogVisibility, children}) => {
  
    const editor = useSlate()
  
    return (
      <button
        className={'menu_button button-link ' + (isBlockActive(editor,'link') ? 'active' : '')}
        onMouseDown={() => toggleLink(editor, changeLinkDialogVisibility)}
      >
        <FiLink2 />
        {children}
      </button>
    )
  
  }
  
  const toggleLink = (editor, changeLinkDialogVisibility) => {
  
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
    else {
      changeLinkDialogVisibility(true)
    }
  
  }