import React from 'react'
import { useSlate } from 'slate-react'
import Dialog from './Dialog'
import { insertLink } from '../textfield/Link'

const LinkDialog = ({changeLinkDialogVisibility, textfieldFocusChange}) => {

    const submit = () => {
        confirmAddingLink(textfieldFocusChange, changeLinkDialogVisibility, editor)
    }

    const editor = useSlate()

    const title = <>Add Link</>

    const content = <>
        <form className='item_dialog_form'>
            <label htmlFor="item_title">URL:</label>
            <input type="text" id="item_title" name='title'/>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="OK" onClick={submit} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeLinkDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={content} 
        changeDialogVisibility={changeLinkDialogVisibility}
        submit={submit}
        />
    )
}

export default LinkDialog

const confirmAddingLink = (textfieldFocusChange, changeItemDialogVisibility, editor) => {

    const url = document.getElementById('item_title').value

    insertLink(editor, url)

    changeItemDialogVisibility(false)

    textfieldFocusChange(false)

    /*const form = document.querySelector('.item_dialog_form')

    const date = form.date.value ? +form.date.value.replaceAll('-','') : 0;

    editContent([...value, {
        title: form.title.value,
        content: [],
        date: date,
        category: form.category.value
    }])

    //console.log(form.title);

    changeItemDialogVisibility(false)*/

}