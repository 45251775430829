import React, {useEffect} from 'react'
import { FiX } from 'react-icons/fi';

const Dialog = ({title, content, changeDialogVisibility, no_bg, submit, close_button}) => {

    useEffect(() => {

        const shortcutCheck = e => {

            if (e.key === "Escape") {
                changeDialogVisibility(false)
            }
            else if (e.key === "Enter") {
                e.preventDefault()
                submit && submit()
            }

        }

        document.addEventListener('keydown', shortcutCheck);
        return () => document.removeEventListener('keydown', shortcutCheck);

    }, [changeDialogVisibility, submit]);

    return (<>
        <div className='dialog box no_drag_action'>
            <div className="dialog_title">
                {title}
                {close_button && <div className="close_button" onClick={() => changeDialogVisibility(false)}><FiX /></div>}
            </div>
            <div className="dialog_content">{content}</div>
        </div>
        {!no_bg && <div className="dialog_bg" onClick={() => changeDialogVisibility(false)}></div>}
    </>)
}

export default Dialog