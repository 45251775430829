import React, { useState, useEffect } from 'react'
import { FiCalendar, FiHome, FiInfo, FiLogOut, FiSave, FiSettings, FiTrash, FiX } from 'react-icons/fi';
import CategoryView from './CategoryView';
import { save, logout } from './functions';
import HomeScreen from './HomeScreen';
import SettingsDialog from './dialogs/SettingsDialog'
import Calendar from './Calendar';
import Trash from './Trash';
import Banner from './Banner';
import { isDemo } from './functions';
import AboutDialog from './dialogs/About';

const Main = ({changeLoginStatus, content, editContent, settings, changeSettings, unsaved_changes, changeChangesStatus, trash, editTrash}) => {

	const [current_tab, openTab_] = useState(0)
	const [tab_mobile_open, changeMobileTabVisibility] = useState(null)
	const [settings_dialog_visible, changeSettingsDialogVisibility] = useState(false)
	const [about_dialog_visible, changeAboutDialogVisibility] = useState(false)
	const [current_category, openCategory] = useState(['home'])

	const openTab = (new_tab) => {
		if (new_tab !== current_tab) {
			openTab_(new_tab)
		}
	}

	useEffect(() => {

        const handleHashChange = () => {

            var redirectToURL = document.URL.replace(/.*#/, "");

			if (!redirectToURL.length && tab_mobile_open) {
				changeMobileTabVisibility(false)
				openCategory(['home'])
			}
        }

        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);

    }, [tab_mobile_open]);

	useEffect(() => {

        const shortcutCheck = e => {
            if ((e.ctrlKey || e.metaKey) && e.key === 's') {
                e.preventDefault()
                save(content, settings, trash, changeChangesStatus, unsaved_changes)          
            }
        }

        document.addEventListener('keydown', shortcutCheck);
        return () => document.removeEventListener('keydown', shortcutCheck);

    }, [content, settings, trash, changeChangesStatus, unsaved_changes]);

	return (
		<div 
		className={"main-container " + (tab_mobile_open ? 'tab-mobile-visible ' : 'tab-mobile-hidden ') + (isDemo() ? 'demo' : '')} 
		style={{'--mainColor': settings.mainColor, '--sidebarWidth': settings.sidebarWidth+'px'}}
		>
			<SideMenu 
			current_category={current_category}
			openCategory={openCategory} 
			content={content} 
			openTab={openTab}
			unsaved_changes={unsaved_changes}
			changeLoginStatus={changeLoginStatus}
			changeChangesStatus={changeChangesStatus} 
			settings={settings}
			changeSettingsDialogVisibility={changeSettingsDialogVisibility}
			trash={trash}
			changeAboutDialogVisibility={changeAboutDialogVisibility}
			/>
			
			<MainContent 
			current_category={current_category}
			content={content} editContent={editContent}
			current_tab={current_tab} openTab={openTab} 
			changeMobileTabVisibility={changeMobileTabVisibility} 
			settings={settings} changeSettings={changeSettings}
			changeChangesStatus={changeChangesStatus}
			openCategory={openCategory}
			trash={trash} editTrash={editTrash} />

			<MobileMenu 
			content={content}
			settings={settings}
			changeChangesStatus={changeChangesStatus}
			unsaved_changes={unsaved_changes}
			changeSettingsDialogVisibility={changeSettingsDialogVisibility}
			changeLoginStatus
			/>

			<Banner title="Saving changes not possible" content={<>
				Changes could not be saved since this is only a demo version of <b>todo.</b> <br/>
				For further assistance contact <a href='mailto:office@btdesign.at' className='banner_link'>office@btdesign.at</a><b>.</b>
			</>} className="demo_saving_banner"/>

			{settings_dialog_visible && <SettingsDialog 
			settings={settings}
			changeSettings={changeSettings}
			changeSettingsDialogVisibility={changeSettingsDialogVisibility}
			content={content}
			editContent={editContent}/>}

			{about_dialog_visible && <AboutDialog
			changeDialogVisibility={changeAboutDialogVisibility}/>}

		</div>
	);
}

export default Main;

const MainContent = ({current_category, content, editContent, current_tab, openTab, changeMobileTabVisibility, settings, changeSettings, changeChangesStatus, openCategory, trash, editTrash}) => {
	
	switch (current_category[0]) {
		case 'category':
			return <CategoryView
			content={content} 
			editContent={editContent}
			current_tab={current_tab}
			openTab={openTab} 
			changeMobileTabVisibility={changeMobileTabVisibility}
			settings={settings}
			changeSettings={changeSettings} 
			current_category={current_category} 
			changeChangesStatus={changeChangesStatus}
			/>
		case 'home':
			return <HomeScreen 
			content={content} editContent={editContent}
			settings={settings}
			changeMobileTabVisibility={changeMobileTabVisibility}
			openTab={openTab}
			current_category={current_category} openCategory={openCategory}
			/>
		case 'calendar':
			return <Calendar content={content} openCategory={openCategory} openTab={openTab}/>
		case 'trash':
			return <Trash trash={trash} editTrash={editTrash} content={content} editContent={editContent} settings={settings} changeSettings={changeSettings}/>
		default:
			break;
	}
}

const SideMenu = ({current_category, openCategory, content, openTab, unsaved_changes, changeLoginStatus, changeChangesStatus, settings, changeSettingsDialogVisibility, trash, changeAboutDialogVisibility}) => {

	const openCategory0 = (cat) => {
		openTab(0)
		openCategory(cat)
	}

	return (<>
		<div className='side_menu unselectable'>
			<div className="side_menu_icon_container">
				<img src='/logo.svg' alt='' className="side_menu_icon"/>
				<div className="side_menu_icon_text">todo</div>

				{current_category[0] !== 'category' && <div className="mobile_top_menu">
					{[['home', <FiHome />], ['calendar', <FiCalendar />], ['trash', <FiTrash />]].map((item, i) =>
						<div 
						className={"mobile_top_menu_item"+(current_category[0] === item[0] ? ' active' : '')} 
						onClick={() => openCategory([ item[0] ])}
						key={'mobile_top_menu_item_'+i}>
							{item[1]}
						</div>
					)}
				</div>}
			</div>
			<div className="side_menu_content">
				<div className="side_menu_content_inner">
					<div className="side_menu_section side_menu_home">
						<div 
						className={"side_menu_item"+(current_category[0] === 'home' ? ' active' : '')} 
						onClick={() => openCategory(['home'])}>
							<div className="side_menu_item_icon"><FiHome /></div>
							<div className="side_menu_item_text">Home</div>
						</div>
						<div 
						className={"side_menu_item"+(current_category[0] === 'calendar' ? ' active' : '')} 
						onClick={() => openCategory(['calendar'])}>
							<div className="side_menu_item_icon"><FiCalendar /></div>
							<div className="side_menu_item_text">Calendar</div>
						</div>
						<div
						className={'side_menu_item'+(current_category[0] === 'trash' ? ' active' : '')}
						onClick={() => openCategory(['trash'])}>
							<div className="side_menu_item_icon"><FiTrash /></div>
							<div className="side_menu_item_text">Recycle Bin</div>
						</div>
					</div>
					<div className="side_menu_section side_menu_general">
						<div className="side_menu_categories">
							<div className="side_menu_category_heading">Categories</div>

							{content.map((c,i) => 
								<div 
								key={'side_menu_item_'+c.id}
								className={"side_menu_item side_menu_item_category"+(i === current_category[1] ? ' active' : '')} 
								style={{'--categoryColor': c.color}}
								onClick={() => openCategory0(['category',i])}>
									<div className="side_menu_item_icon">
										<div className="side_menu_item_icon_category category_color"></div>
									</div>
									<div className="side_menu_item_text">{c.title}</div>
								</div>
							)}
						</div>
					</div>
					<div className="side_menu_section">
						<div 
						className="side_menu_item" 
						onClick={() => changeSettingsDialogVisibility(true)}>
							<div className="side_menu_item_icon"><FiSettings /></div>
							<div className="side_menu_item_text">Settings</div>
						</div>
						<div className={"side_menu_item demo_disabled"+(unsaved_changes ? ' unsaved_changes' : '')} onClick={() => save(content, settings, trash, changeChangesStatus, unsaved_changes)}>
							<div className="side_menu_item_icon"><FiSave /></div>
							<div className="side_menu_item_text">Save Changes</div>
						</div>
						{isDemo() ? 
						<div className="side_menu_item" onClick={() => changeAboutDialogVisibility(true)}>
							<div className="side_menu_item_icon"><FiInfo /></div>
							<div className="side_menu_item_text">About</div>
						</div> :
						<div className="side_menu_item" onClick={() => logout(changeLoginStatus, content, unsaved_changes, settings, trash)}>
							<div className="side_menu_item_icon"><FiLogOut /></div>
							<div className="side_menu_item_text">Log Out</div>
						</div>
						}
						
					</div>
				</div>
			</div>
		</div>
	</>)
}

const MobileMenu = ({content, settings, changeChangesStatus, unsaved_changes, changeSettingsDialogVisibility, changeLoginStatus}) => {

	return <div className="mobile_menu unselectable">
		<div className="mobile_menu_item" onClick={() => logout(changeLoginStatus, content, unsaved_changes, settings)}><FiLogOut /> Log Out</div>
		<div className="mobile_menu_item" onClick={() => changeSettingsDialogVisibility(true)}><FiSettings /> Settings</div>
		<div className={"mobile_menu_item"+(unsaved_changes ? ' unsaved_changes' : '')} onClick={() => save(content, settings, changeChangesStatus, unsaved_changes)}><FiSave /> Save</div>
		<div className="end_mobilemoveitem">
			<div className="mobile_menu_item" onClick={endMovingItems}><FiX /> End moving items</div>
		</div>
	</div>

}

const endMovingItems = () => {
	document.querySelector('.main-container').classList.remove('mobilemoveitem');
}
