import React, { useState } from 'react'
import Dialog from './Dialog'

export const ChangeCategoryDialog = ({changeDialogVisibility, content, editContent, current_category, id}) => {

    const [selected_category, categoryChange] = useState(content[id].category)

    const confirmCategoryChange = () => {
        let new_content = [...content]

        const item_to_move = content[current_category].content.splice(id, 1)
        
        new_content[selected_category].content.push(...item_to_move)

        editContent(new_content)
        changeDialogVisibility(false)
    }

    const title = <>Change Category</>

    const dialog_content = <>
        <form>
            <label htmlFor='item_new_category'>Select New Category:</label>
            <div className="select_container">
                <select id="item_new_category" name='category' onChange={e => categoryChange(e.target.value)} defaultValue={selected_category}>
                    {content.map((c,i) => 
                        <option value={i} key={'cat_option_'+c.id}>{c.title}</option>
                    )}
                </select>
            </div>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="OK" onClick={confirmCategoryChange} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={dialog_content} 
        changeDialogVisibility={changeDialogVisibility}
        />
    )
}

export const ChangeDateDialog = ({changeDialogVisibility, content, editContent, current_category, id}) => {

    let default_date
    if (content[id].date) {
        const item_date = String(content[id].date)
    
        default_date = item_date.substring(0, 4)+'-'+item_date.substring(4, 6)+'-'+item_date.substring(6, 8)
    }
    

    const [selected_date, dateChange] = useState(default_date)

    const confirmDateChange = () => {

        let new_content = [...content]

        if (!!selected_date) { // New date entered -> change item date accordingly
            new_content[current_category].content[id].date = selected_date;
        }
        else { //No date entered -> remove date property
            delete new_content[current_category].content[id].date;
        }

        editContent(new_content)
        changeDialogVisibility(false)
        
    }

    const title = <>Change Due Date</>

    const dialog_content = <>
        <form>
            <label htmlFor='item_new_date'>New Due Date:</label>
            <input type="date" id='item_new_date' name='date' onChange={e => dateChange(e.target.value)} defaultValue={selected_date}/>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="OK" onClick={confirmDateChange} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={dialog_content} 
        changeDialogVisibility={changeDialogVisibility}
        />
    )
}

