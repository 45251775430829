import axios from "axios"

export const addItem = (content, editContent, i, j, openCategory, openTab, changeMobileTabVisibility) => {

    let new_content = [...content]

    new_content[i].content.push({title: "", content: [{type: 'paragraph', children: [{ text: '' },],},]})

    editContent(new_content)

    openCategory && openCategory(['category', i])
    changeMobileTabVisibility && changeMobileTabVisibility(true)

    if (window.innerWidth <= 810) {
        changeWindowLocation(j)
    }

    openTab(j)
    
}

export const logout = (changeLoginStatus, content, unsaved_changes, settings, trash) => {

    save(content, settings, trash, null, unsaved_changes)

    fetch('/api/logout.php')
        .then(response => response.json())
        .then(data => {
            window.setTimeout(() => {
                changeLoginStatus(false)
            }, 1)
        })

}

export const save = (content, settings, trash, changeChangesStatus, unsaved_changes, e) => {

    e && e.preventDefault()

    if (isDemo()) {
        openBanner('demo_saving_banner')
        return;
    }

    if (!unsaved_changes) return;

    axios.post('/api/save_changes.php', {
        content: JSON.stringify(content),
        settings: JSON.stringify(settings), //JSON.stringify({...settings, sidebarWidth: 200})
        trash: JSON.stringify(trash)
    })
    .then(() => {
        changeChangesStatus(false)
    })
    .catch((error) => {
        //Error getting data from Api. To get status code: error.response.status
        console.log(error);
    })
}

export const changeWindowLocation = id => {
    var redirectToURL = document.URL.replace(/#.*$/, "");
    redirectToURL = redirectToURL + '#' + id;
    window.location.href = redirectToURL; 
}

export const displayPrettyDate = date => {
    return date.substring(8,10) + '.' + date.substring(5,7) + '.' + date.substring(0,4)
}

export const dateNumbersToString = (y, m, d) => {

    const two_digit_day = d < 10 ? '0'+d : ''+d,
    two_digit_month = m < 10 ? '0'+m : ''+m

    //console.log(two_digit_month, two_digit_day);

    return y+'-'+two_digit_month+'-'+two_digit_day
}

export const convertToSaveString = str => {
    return str.toLowerCase().replace(/[^a-z1-9]/g, '');
}

export const isDemo = () => {
    let params = new URLSearchParams(window.location.search);
    if (params.has("demo") && params.get("demo") === "1") {
        return true;
    }
    return false;
}

const openBanner = (name) => {
    document.querySelector('.main-container').classList.add(name+'_active')

    window.setTimeout(() => {
        document.querySelector('.main-container').classList.remove(name+'_active')
    }, 15000)
}