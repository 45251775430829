import React from 'react'

const Button = ({children, icon, onClick, className, fontSize}) => {

	const additionalClasses = className || '';
	const fixedFontSize = fontSize || 14;

	return (
		<div className={'button '+additionalClasses} onClick={onClick} style={{fontSize: fixedFontSize}}>
			<div className="button_icon">{icon}</div>
			{children}
		</div>
	)
}

export default Button