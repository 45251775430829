import React, {useState, useEffect} from 'react'
import { isMarkActive, toggleMarkValue, toggleMark } from './textfield/CustomEditor'
import { FiChevronLeft, FiChevronRight, FiAlignLeft, FiAlignCenter, FiAlignRight, FiAlignJustify, FiMoreHorizontal, FiChevronDown, FiX, /*FiMenu, FiType*/ } from 'react-icons/fi'
import { BsListOl, BsListUl, BsListCheck } from 'react-icons/bs'
import { GrTextAlignFull } from 'react-icons/gr'
import LinkDialog from './dialogs/LinkDialog'
import ImageDialog from './dialogs/ImageDialog'
import { ToggleLinkButton } from './textfield/Link'
import { useSlate } from 'slate-react'
import { InsertImageButton } from './textfield/Image'
import BlockButton from './textfield/ToggleBlockButton'
import { Editor } from 'slate'
import Button from './Button'

const MenuBar = (props) => {

    const [link_dialog_visible, changeLinkDialogVisibility] = useState(false)
    const [image_dialog_visible, changeImageDialogVisibility] = useState(false)

    return (<>

        <nav className={"header-menu unselectable textfield_focus_"+props.textfield_focused}>
            <div className="header-history box">
                <HistoryButton forward={false}><FiChevronLeft /></HistoryButton>
                <HistoryButton forward={true}><FiChevronRight /></HistoryButton>
            </div>
            <div className="header-left-side box">
                
                <DropDownMenu icon={<div className='dropdown_font_size_text'>{props.fontsize}</div>} id='dropdown_font_size' class_name={'small_arrow'}>
                    <FontSizeButtons fontsize={props.fontsize}/>
                </DropDownMenu>

                <DropDownMenu icon={<div className='color_icon'>A</div>} id='dropdown_color' class_name={'small_arrow'}>
                    <ColorButtons />
                </DropDownMenu>

                <div className="menu-seperator ms0"></div>

                <TextStyleButtons />

                <div className="menu-seperator ms1"></div>

                <AlignButtons />
                <DropDownMenu icon={<GrTextAlignFull />} id='dropdown_align'>
                    <AlignButtons />
                </DropDownMenu>

                <div className="menu-seperator ms2"></div>

                <ToggleLinkButton changeLinkDialogVisibility={changeLinkDialogVisibility}/>
                <InsertImageButton changeImageDialogVisibility={changeImageDialogVisibility}/>

                <div className="menu-seperator ms3"></div>
                
                <ListButtons />
                <DropDownMenu icon={<BsListUl />} id='dropdown_lists' bl>
                    <ListButtons />
                </DropDownMenu>

                <DropDownMenu icon={<FiMoreHorizontal />} id='dropdown_more' right>
                    <TextStyleButtons />
                    <AlignButtons />
                    <ListButtons />
                    <ToggleLinkButton changeLinkDialogVisibility={changeLinkDialogVisibility}><div className="button_text">Insert Link</div></ToggleLinkButton>
                    <InsertImageButton changeImageDialogVisibility={changeImageDialogVisibility}><div className="button_text">Insert Image</div></InsertImageButton>
                </DropDownMenu>

            </div>
            
		</nav>

        {link_dialog_visible && <LinkDialog 
        changeLinkDialogVisibility={changeLinkDialogVisibility}
        textfieldFocusChange={props.textfieldFocusChange}/>}

        {image_dialog_visible && <ImageDialog 
        changeImageDialogVisibility={changeImageDialogVisibility}/>}
    </>)
}
export default MenuBar

const MenuButton = ({children, format}) => {

    const editor = useSlate()

    return <button
    onMouseDown={() => toggleMark(editor, format)}
    className={(isMarkActive(editor,format) ? 'active' : '') + ' menu_button button-'+format}
    >
        {children}
    </button>
}

const HistoryButton = ({children, forward}) => {

    const editor = useSlate()

    const { history } = editor
    const { undos } = history
    const { redos } = history

    const enabled = forward ? !!redos.length : !!undos.length

    return <button 
    className={'history_button '+(enabled ? '' : 'inactive')}
    onMouseDown={forward ? editor.redo : editor.undo}
    >
        {children}
    </button>

}

export const DropDownMenu = ({icon, children, id, bl, right, no_arrow, active, class_name, changeDropDownVisibility}) => {

    const class_nm = class_name || ''

    const [menu_visible, changeVisibility_] = useState(false)

    const changeVisibility = visibility => {
        changeDropDownVisibility && changeDropDownVisibility(visibility)
        changeVisibility_(visibility)
    }

    const toggleMenu = e => {

        e.preventDefault()

        changeVisibility(!menu_visible)

        document.body.addEventListener('mousedown', closeMenu, true)

    }

    const closeMenu = e => {

        !e.target.closest('#'+id) && changeVisibility(false)

        document.body.removeEventListener('mousedown', closeMenu)

    }

    return <div className={"header-dropdown-menu "+(right ? 'align-right ' : '')+(no_arrow ? 'no-arrow ' : '')+class_nm+(menu_visible ? ' active' : '')} id={id}>
        {menu_visible && <div className="header-dropdown-menu-content" onMouseDown={() => changeVisibility(false)}>{children}</div>}
        <button className={"header-dropdown-menu-icon "+(bl ? 'bl-icon ' : '')+(active ? 'always-active' : '')} onMouseDown={toggleMenu}>
            {icon} 
            {!no_arrow && <div className="header-dropdown-menu-arrow"><FiChevronDown /></div>}
        </button>
    </div>
}

const TextStyleButtons = () => {
    return <>
        <MenuButton format='bold'>B<div className="button_text">Bold</div></MenuButton>
        <MenuButton format='italic'>I<div className="button_text">Italic</div></MenuButton>
        <MenuButton format='underline'>U<div className="button_text">Underline</div></MenuButton>
    </>
}

const AlignButtons = () => {
    return <>
        <BlockButton format="left"><FiAlignLeft /><div className="button_text">Left</div></BlockButton>
        <BlockButton format="center"><FiAlignCenter /><div className="button_text">Center</div></BlockButton>
        <BlockButton format="right"><FiAlignRight /><div className="button_text">Right</div></BlockButton>
        <BlockButton format="justify"><FiAlignJustify /><div className="button_text">Justify</div></BlockButton>
    </>
} 

const ListButtons = () => {
    return <>
        <BlockButton bl format="bulleted-list"><BsListUl /><div className="button_text">Bulleted List</div></BlockButton>
        <BlockButton bl format="numbered-list"><BsListOl /><div className="button_text">Numbered List</div></BlockButton>
        <BlockButton bl format="check-list-item"><BsListCheck /><div className="button_text">Check List</div></BlockButton>
    </>
}

const ColorButtons = () => {

    const editor = useSlate()

    const swatches = [
        ['#C76B6B', '#ecaa78', '#ecdf73', '#76B574', '#5983CA', '#b16db1']
    ]

    return <div className="color_dropdown_content no_hover_effect">
        {swatches.map((s_row, i) => 
            <div className='color_row' key={'color_row_'+i}>
                {s_row.map(color => 
                    <div 
                    key={'color_indicator_'+color}
                    className='color_indicator' 
                    onMouseDown={() => toggleMarkValue(editor, 'color', color)}
                    style={{backgroundColor: color}}
                    ></div>
                )}
            </div>
        )}
        <div style={{marginTop: 10, marginBottom: -5}}>
            <Button icon={<FiX />} onMouseDown={() => Editor.removeMark(editor, 'color')} className={'no_bg p0'}>
                Default Color
            </Button>
        </div>
        
    </div>
}

const FontSizeButtons = ({fontsize}) => {

    const editor = useSlate()
    const active_fs = fontsize || 16
    const list = [6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 36, 44]

    useEffect(() => {
        document.querySelector('.font_size_options button.active').scrollIntoView()

        const fontSizeKeyDown = e => {

            e.preventDefault()

            const pos = list.indexOf(active_fs)
            let new_fontsize

            switch (e.key) {
                case 'ArrowDown':
                    if(list[pos-1]) new_fontsize = list[pos-1]
                    break;
                case 'ArrowUp':
                    if(list[pos+1]) new_fontsize = list[pos+1]
                    break;
                default:
                    break;
            }

            if (new_fontsize && new_fontsize !== active_fs) {
                toggleMarkValue(editor, 'size', new_fontsize)
            }
        }

        document.addEventListener('keydown', fontSizeKeyDown);
        return () => document.removeEventListener('keydown', fontSizeKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active_fs, editor])

    return <div className='no_hover_effect font_size_options' style={{padding: 0}}>
        {list.map(i => 
            <button 
            key={'font_size_'+i}
            className={active_fs === i ? 'active' : ''}
            onMouseDown={() => toggleMarkValue(editor, 'size', i)}>
                <div className="button_text">{i}</div>
            </button>
        )}
    </div>

}