import {
    Editor,
    Transforms,
    Range,
    Point,
    Element as SlateElement,
  } from 'slate'
import { useSlateStatic, useReadOnly, ReactEditor } from "slate-react"
import { FiCheck } from 'react-icons/fi'


export const withChecklists = editor => {
    const { deleteBackward } = editor
  
    editor.deleteBackward = (...args) => {
      const { selection } = editor
  
      if (selection && Range.isCollapsed(selection)) {
        const [match] = Editor.nodes(editor, {
          match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === 'check-list-item',
        })
  
        if (match) {
          const [, path] = match
          const start = Editor.start(editor, path)
  
          if (Point.equals(selection.anchor, start)) {
            const newProperties = {
              type: 'paragraph',
            }
            Transforms.setNodes(editor, newProperties, {
              match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'check-list-item',
            })
            return
          }
        }
      }
  
      deleteBackward(...args)
    }
  
    return editor
  }
  
  export const CheckListItemElement = ({ attributes, children, element, style }) => {
    const editor = useSlateStatic()
    const readOnly = useReadOnly()
    const { checked } = element

    /*
    FIX for checklist align. 
    Apply margin(-left): auto because the css property 'text-align' 
    would only cause the text to be centered, not the checkbox.
    */
    let style_for_alignment = {};
    if (style.textAlign === 'center') {
      style_for_alignment = {margin: 'auto', width: 'fit-content'}
    }
    if (style.textAlign === 'right') {
      style_for_alignment = {marginLeft: 'auto', width: 'fit-content'}
    }

    return (
      <div
        {...attributes}
        style={{display: 'flex', ...style, textAlign: 'left', ...style_for_alignment}}
        className={'check_container list-item checked_'+!!checked+(' check-container-align-'+style.textAlign)}
      >
        <div
          contentEditable={false}
        >
          <div className="checkbox"></div>
          <div 
          className="checkmark"
          onClick={() => {
            if (readOnly) return;
            const path = ReactEditor.findPath(editor, element)
            const newProperties = {
              checked: !checked,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
          }}
          ><FiCheck /></div>
        </div>
        <span
          contentEditable={!readOnly}
          suppressContentEditableWarning
          style={{flex: 1}}
          className={'label_check'}
        >
          {children}
        </span>
      </div>
    )
  }


  /*export const toggleCheckList = (editor) => {
    const match = isCheckListActive(editor)
    if (match) {
        unwrapCheckList(editor)
    }
  
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const chk = {
      type: 'check-list-item',
      children: isCollapsed ? [{ text: 'url' }] : [],
    }
  
    if (isCollapsed) {
      Transforms.setNodes(
        editor,
        { type: match ? 'paragraph' : 'check-list-item' },
        { match: n => Editor.isBlock(editor, n) }
      )
    } else {
      Transforms.wrapNodes(editor, chk, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  }

  const isCheckListActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'check-list-item',
    })
    return !!link
  }

  const unwrapCheckList = editor => {
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'check-list-item',
    })
  }*/