import React, { useState } from 'react'
import Dialog from './Dialog'

export const EmptyTrashDialog = ({changeDialogVisibility, editTrash}) => {

    const emptyBin = () => {
        editTrash({})
        changeDialogVisibility(false);
    }

    const title = <>Empty Recycle Bin</>

    const dialog_content = <>
        <form>
            <label>This action cannot be undone.</label>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="Empty Bin" onClick={emptyBin} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={dialog_content} 
        changeDialogVisibility={changeDialogVisibility}
        />
    )
}

export const DeleteTrashItemDialog = ({changeDialogVisibility, content, editContent, current_category, id}) => {

    let default_date
    if (content[id].date) {
        const item_date = String(content[id].date)
    
        default_date = item_date.substring(0, 4)+'-'+item_date.substring(4, 6)+'-'+item_date.substring(6, 8)
    }
    

    const [selected_date, dateChange] = useState(default_date)

    const confirmDateChange = () => {

        let new_content = [...content]

        if (!!selected_date) { // New date entered -> change item date accordingly
            new_content[current_category].content[id].date = selected_date;
        }
        else { //No date entered -> remove date property
            delete new_content[current_category].content[id].date;
        }

        editContent(new_content)
        changeDialogVisibility(false)
        
    }

    const title = <>Change Due Date</>

    const dialog_content = <>
        <form>
            <label htmlFor='item_new_date'>New Due Date:</label>
            <input type="date" id='item_new_date' name='date' onChange={e => dateChange(e.target.value)} defaultValue={selected_date}/>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="OK" onClick={confirmDateChange} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={dialog_content} 
        changeDialogVisibility={changeDialogVisibility}
        />
    )
}

