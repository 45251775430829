import React from 'react'
import { useSlate } from 'slate-react'
import Dialog from './Dialog'
import { insertImage } from '../textfield/Image'

const ImageDialog = ({changeImageDialogVisibility}) => {

    const submit = () => {
        confirmAddingImage(changeImageDialogVisibility, editor)
    }

    const editor = useSlate()

    const title = <>Add Image</>

    const content = <>
        <form className='item_dialog_form'>
            <label htmlFor="item_title">URL:</label>
            <input type="text" id="item_title" name='title'/>
            <fieldset className='item_dialog_button_container form_fieldset'>
                <input type="button" value="OK" onClick={submit} className='active'/>
                <input type="reset" value="Cancel" onClick={() => changeImageDialogVisibility(false)}/>
            </fieldset>
        </form>
    </>

    return (
        <Dialog 
        title={title} 
        content={content} 
        changeDialogVisibility={changeImageDialogVisibility}
        submit={submit}
        />
    )
}

export default ImageDialog

const confirmAddingImage = (changeImageDialogVisibility, editor) => {

    const url = document.getElementById('item_title').value

    insertImage(editor, url)

    changeImageDialogVisibility(false)

}