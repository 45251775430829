import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './style/style.css';
import './style/font.css';
import './style/responsive.css';
import Main from './components/Main';
import Login from './components/Login';
import { isDemo, dateNumbersToString } from './components/functions';

/* kanban */

const App = () => {

	const standard_content = [
		{
			"title": 'New Category',
			"id": 1,
			"color": '#5983CA',
			"content": [
				{"title": "New Item", "content": [
					{"type":"paragraph","children":[{"text":""}]},
				]},
			],
			"collapsed": false,
		},
	]

	//console.log( encodeURIComponent(JSON.stringify(standard_content)) );

	const [logged_in, changeLoginStatus] = useState(undefined)
	const [content, editContent_] = useState(standard_content)
	const [settings, changeSettings_] = useState({
		sidebarWidth: 200,
		sidebarSortingType: 0,
		mainColor: '89, 131, 202',
		categories: {
			'nocategory': {title: 'No Category',color: 'transparent',},
			'green': {title: 'Green',color: '#76B574'},
			'red': {title: 'Red',color: '#C76B6B'},
			'blue': {title: 'Blue',color: '#5983CA'}
		},
		/*layout: 0, /*0 = standard, 1 = compact*/
		spellCheck: 1,
		/*compactSidebar: 0,*/
		activateTrash: true,
	})
	const [trash, editTrash_] = useState([])

	const [unsaved_changes, changeChangesStatus] = useState(false)

	const editContent = (content, deleted_item) => {

		changeChangesStatus(true)

		if (content.length === 0) { //User has deleted all items -> display standard item
			editContent_(standard_content)
		}
		else {
			editContent_(content);
		}

		if (deleted_item) {
			editTrash([...trash, deleted_item])
		}
		
	}
	const changeSettings = (settings) => {
		changeChangesStatus(true)
		changeSettings_(settings);
	}
	const editTrash = (trash) => {
		changeChangesStatus(true)
		editTrash_(trash);
	}

	useEffect(() => {

        if (/electron/i.test(navigator.userAgent)) {
            import('./style/electron.css')
            /*.then((something) => {
               console.log(something.something);
            });*/
        }

		axios.get('/api/login_check_authorization.php')
		.then((res) => {

			if (typeof res.data === 'string') {
				throw Error()
			}

			if (res.data.successful) {
				changeLoginStatus(true);

			    const content = JSON.parse(res.data.content.content)
				const settings = JSON.parse(res.data.content.settings)
				const trash = JSON.parse(res.data.content.trash)
				!!content && editContent_(content);
				!!settings && changeSettings_(settings);
				!!trash && editTrash_(trash);
			}
			else {
				if (isDemo()) {

					var d = new Date();
    				d.setDate(d.getDate() + 10);
					const date_string = dateNumbersToString(d.getFullYear(), d.getMonth()+1, d.getDate()) //`2023-${d.getMonth()}-${d.getDate()}`
					
					const demo_content = [
						{
							"title": 'Overview',
							"id": 1,
							"color": '#C76B6B',
							"content": [
								{"title": "An Item", "content": [
									{"type":"paragraph","children":[{"text":""}]},
								]},
								{"title":"An Item with Content","content":[
									{"type":"paragraph","children":[{"text":"This item has a text as its main content."}]},
									{"type":"paragraph","children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":"Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}]},
									{"type":"paragraph","children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":"Consequat interdum varius sit amet mattis. Est lorem ipsum dolor sit amet consectetur adipiscing elit. Tincidunt lobortis feugiat vivamus at. In iaculis nunc sed augue lacus viverra vitae. Tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Lorem dolor sed viverra ipsum nunc aliquet. Sit amet luctus venenatis lectus magna fringilla urna porttitor. Volutpat lacus laoreet non curabitur gravida arcu ac. Consequat semper viverra nam libero justo."}]},
									{"type":"paragraph","children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":"Faucibus scelerisque eleifend donec pretium vulputate sapien nec. Tellus orci ac auctor augue mauris augue neque gravida in. Non tellus orci ac auctor augue mauris augue neque gravida. Id velit ut tortor pretium viverra. Morbi leo urna molestie at elementum eu. Nec sagittis aliquam malesuada bibendum arcu vitae. Tincidunt tortor aliquam nulla facilisi."}]}
								]},
								{"title":"Text Formatting","content":[
									{"type":"paragraph","children":[{"text":"Text","bold":true},{"text":" can be "},{"text":"formatted","italic":true},{"text":" like in "},{"text":"every","underline":true},{"text":" "},{"text":"WYSIWYG","color":"#C76B6B","bold":true},{"text":" "},{"text":"editor","color":"#76B574","bold":true},{"text":"."}]},
									{"type":"paragraph","children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":"Alignment and "},{"text":"font size","size":36},{"text":" can changed too."}],"align":"center"}
								]},
								{"title":"An Item with date","content":[
									{"type":"paragraph","children":[{"text":"You can set a due date for every item. Just hover over it in the side bar and click on the three dots to open the menu."}]}
								],"date":date_string},
								{"title":"Lists","content":[
									{"type":"check-list-item","children":[{"text":"Excepteur"}],"checked":true},
									{"type":"check-list-item","children":[{"text":"Sint occaecat"}],"checked":false},
									{"type":"paragraph","checked":false,"children":[{"text":""}]},
									{"type":"paragraph","checked":false,"children":[{"text":"A Sub-Heading","bold":true}]},
									{"type":"check-list-item","children":[{"text":"Cupidatat"}]},
									{"type":"check-list-item","children":[{"text":"Non proident"}],"checked":true},
									{"children":[{"text":""}],"checked":false},
									{"checked":false,"children":[{"text":"Other List types are also possible:","bold":true}]},
									{"type":"bulleted-list","children":[{"checked":false,"children":[{"text":"Massa enim"}],"type":"list-item"},{"checked":false,"type":"list-item","children":[{"text":"Nec dui"}]}]},
									{"checked":false,"type":"paragraph","children":[{"text":""}]},
									{"type":"numbered-list","children":[{"checked":false,"type":"list-item","children":[{"text":"Nunc mattis"}]},{"checked":false,"type":"list-item","children":[{"text":"Enim ut tellus"}]}]}
								]},
								{"title":"Images and Links","content":[
									{"type":"paragraph","children":[{"text":"You can add images"}]},
									{"type":"image","url":"/logo.svg","size":248,"children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":""}]},
									{"type":"paragraph","children":[{"text":"...and "},{"type":"link","url":"https://www.btdesign.at","children":[{"text":"links"}]},{"text":" to the item's content."}]}
								]}
							],
							"collapsed": false,
						},
						{
							"title": 'Lorem ipsum',
							"id": 2,
							"color": '#eabe39',
							"content": [
								{"title": "Sample Item", "content": [
									{"type":"paragraph","children":[{"text":"Mauris vitae ultricies leo integer malesuada nunc vel risus. Eu nisl nunc mi ipsum. Dignissim sodales ut eu sem. Aliquet lectus proin nibh nisl condimentum. Eget duis at tellus at urna condimentum mattis pellentesque. Interdum velit euismod in pellentesque. Faucibus et molestie ac feugiat sed. Odio aenean sed adipiscing diam donec adipiscing tristique risus nec. Imperdiet proin fermentum leo vel. Aliquam sem et tortor consequat. Id leo in vitae turpis massa. Non pulvinar neque laoreet suspendisse interdum consectetur libero id. Sem et tortor consequat id porta nibh venenatis. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem."}]},
								]},
								{"title": "Sample Item", "content": [
									{"type":"paragraph","children":[{"text":"Mauris vitae ultricies leo integer malesuada nunc vel risus. Eu nisl nunc mi ipsum. Dignissim sodales ut eu sem. Aliquet lectus proin nibh nisl condimentum. Eget duis at tellus at urna condimentum mattis pellentesque. Interdum velit euismod in pellentesque. Faucibus et molestie ac feugiat sed. Odio aenean sed adipiscing diam donec adipiscing tristique risus nec. Imperdiet proin fermentum leo vel. Aliquam sem et tortor consequat. Id leo in vitae turpis massa. Non pulvinar neque laoreet suspendisse interdum consectetur libero id. Sem et tortor consequat id porta nibh venenatis. Urna cursus eget nunc scelerisque viverra mauris in aliquam sem."}]},
								]},
								{"title": "", "content": [
									{"type":"paragraph","children":[{"text":""}]},
								]},
							],
							"collapsed": false,
						},
						{
							"title": 'Dolor Sit Amet',
							"id": 3,
							"color": '#76B574',
							"content": [
								{"title": "Sample Item", "content": [
									{"type":"paragraph","children":[{"text":"Nunc sed augue lacus viverra. Nulla posuere sollicitudin aliquam ultrices sagittis. Leo in vitae turpis massa sed elementum tempus egestas sed. Elit ullamcorper dignissim cras tincidunt. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum. Sem viverra aliquet eget sit amet tellus. Senectus et netus et malesuada fames ac. Sit amet facilisis magna etiam tempor orci. Eget nunc lobortis mattis aliquam faucibus purus in massa tempor. Quam vulputate dignissim suspendisse in est ante in nibh. Varius morbi enim nunc faucibus. Ac turpis egestas maecenas pharetra convallis. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Nunc id cursus metus aliquam. Accumsan sit amet nulla facilisi morbi."}]},
								]},
							],
							"collapsed": false,
						},
						{
							"title": 'Consectetur',
							"id": 4,
							"color": '#5983CA',
							"content": [],
							"collapsed": false,
						},
					]

					changeLoginStatus(true);
					editContent_(demo_content);
				}
				else {
					changeLoginStatus(false);
				}
			}
		})
		.catch(e => {
			console.log(e);
			changeLoginStatus(false)
		})

	}, [])

	/**
	 * If there are unsaved changes, ask for confirmation by the user to close page
	 */
	useEffect(() => {
		const unloadAlert = e => {
			if (!unsaved_changes || isDemo()) return;
			if (!e) e = window.event;
			//e.cancelBubble is supported by IE - this will kill the bubbling process.
			e.cancelBubble = true;
			e.returnValue = 'Are you sure you want to leave?'; //This is displayed on the dialog
		
			//e.stopPropagation works in Firefox.
			if (e.stopPropagation) {
				e.stopPropagation();
				e.preventDefault();
			}
		}
		window.addEventListener('beforeunload', unloadAlert);
		return () => {
			window.removeEventListener('beforeunload', unloadAlert);
		}
	}, [unsaved_changes])

	switch (logged_in) {
		case true:
			return <Main 
			changeLoginStatus={changeLoginStatus} 
			content={content} editContent={editContent} 
			settings={settings} changeSettings={changeSettings} 
			unsaved_changes={unsaved_changes} changeChangesStatus={changeChangesStatus}
			trash={trash} editTrash={editTrash}/>
		case false:
			return <Login changeLoginStatus={changeLoginStatus} editContent_={editContent_} changeSettings_={changeSettings_}/>
		default:
			return <></> //Loading
	}
}

export default App